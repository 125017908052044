import { getFullStats } from "src/services/api/api.stats";
import { useEffect, useState } from "react";
import { FullStats, RetroItem } from "src/types/stats";
import { useTranslation } from "react-i18next";
import { BarChart } from "@mui/x-charts";
import TotalsComponent from "./totals";
import Skeleton from "@mui/material/Skeleton";
import { Button, ButtonGroup, Card, CardContent, Divider } from "@mui/material";

export default function TabContentFull(): React.JSX.Element {
  const { t } = useTranslation();
  const [stats, setStats] = useState<FullStats>();
  const [axisLabels, setAxisLabels] = useState<string[]>([]);
  const [series, setSeries] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [height, setHeight] = useState(200);
  const [isDefaultActive, setIsDefaultActive] = useState(true);

  useEffect(() => {
    getFullStats().then((data: FullStats | undefined) => {
      setStats(data);
      const withdraws: number[] = [];
      const tweaksWithdraws: number[] = [];
      const grossDeposits: number[] = [];
      const axisLabels: string[] = [];
      const grossGamingRevenue: number[] = [];
      const dataArr = isDefaultActive
        ? data?.retrospective_by_day
        : data?.retrospective_by_month;

      dataArr?.forEach((item: RetroItem) => {
        axisLabels.push(item.date);
        withdraws.push(item.withdraw);
        grossDeposits.push(item.gross_deposits);
        tweaksWithdraws.push(item.tweaks_withdraw);
        grossGamingRevenue.push(item.gross_gaming_revenue);
      });

      setAxisLabels(axisLabels);
      setSeries([
        {
          data: grossDeposits,
          label: t("STATS.REVENUE_GROSS"),
          stack: "1",
          color: "var(--mui-palette-primary-400)",
        },
        {
          data: tweaksWithdraws,
          label: t("STATS.TWEAKS_WITHDRAWS"),
          stack: "2",
          color: "var(--mui-palette-warning-400)",
        },
        {
          data: grossGamingRevenue,
          label: t("STATS.GROSS_GAMING_REVENUE"),
          stack: "3",
          color: "var(--mui-palette-success-400)",
        },
      ]);
      setLoading(false);
    });

    const handleResize = () => {
      const newHeight = window.innerHeight - 320;
      setHeight(newHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [t, isDefaultActive]);

  const handleButtonMonthsClicked = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    setIsDefaultActive(false);
  };

  const handleButtonDaysClicked = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    setIsDefaultActive(true);
  };

  return (
    <Card>
      <CardContent
        sx={{ padding: "10px 20px", ":last-child": { paddingBottom: "8px" } }}
      >
        {loading ? (
          <>
            <Skeleton
              variant="rectangular"
              height={120}
              sx={{ borderRadius: 2 }}
            />
            <Skeleton
              variant="rectangular"
              height={height}
              style={{ marginTop: 16 }}
              sx={{ borderRadius: 2 }}
            />
          </>
        ) : (
          <>
            <TotalsComponent totals={stats?.totals} />
            <Divider sx={{ padding: 1 }}></Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
                padding: 10,
              }}
            >
              <ButtonGroup variant="outlined" aria-label="Basic button group">
                <Button
                  variant={isDefaultActive ? "contained" : "outlined"}
                  style={{ width: 100 }}
                  onClick={handleButtonDaysClicked}
                >
                  {t("COMMON.DAYS")}
                </Button>
                <Button
                  variant={isDefaultActive ? "outlined" : "contained"}
                  style={{ width: 100 }}
                  onClick={handleButtonMonthsClicked}
                >
                  {t("COMMON.MONTHS")}
                </Button>
              </ButtonGroup>
            </div>
            {stats && isDefaultActive ? (
              <BarChart
                series={series}
                height={height}
                xAxis={[
                  {
                    data: axisLabels,
                    scaleType: "band",
                  },
                ]}
                margin={{ top: 40, bottom: 30, left: 100, right: 10 }}
              />
            ) : (
              <BarChart
                series={series}
                height={height}
                xAxis={[{ data: axisLabels, scaleType: "band" }]}
                margin={{ top: 40, bottom: 30, left: 100, right: 10 }}
              />
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
