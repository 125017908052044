import i18n from "src/i18n/i18n";
import { UserProvider } from "src/components/auth/user-context";
import { ThemeProvider } from "src/components/core/theme-provider/theme-provider";
import { I18nextProvider } from "react-i18next";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { CasesTable } from "src/components/cases/case-table";
import { PromocodesTable } from "src/components/promocodes/promocodes-table";
import { BonusesTable } from "src/components/bonuses/bonuses-table";
import Categories from "src/components/categories/categories";
import { CustomersTable } from "src/components/user/users-table";
import StatsPage from "src/components/stats/stats";
import Layout from "src/components/layout/layout";
import UserPage from "src/components/user/user.page";
import LoginPage from "./components/auth/login-page";
import { LocalizationProvider } from "./components/core/localization-provider";
import { NotificaitonsTable } from "./components/notifications/notifications-table";
import SettingsPage from "./components/settings/settings";
import { ProtectedRoute } from "./components/auth/protected-route";
import { AdminRole } from "./types/admin";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider>
        <LocalizationProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="*"
                element={
                  <UserProvider>
                    <Layout>
                      <Routes>
                        <Route path="/cases" element={<CasesTable />} />
                        <Route path="/promocodes" element={<PromocodesTable />} />
                        <Route path="/bonuses" element={<BonusesTable />} />
                        <Route path="/news" element={<NotificaitonsTable />} />
                        <Route path="/categories" element={<Categories />} />
                        <Route path="/users" element={<CustomersTable />} />
                        <Route path="/users/:id" element={<UserPage />} />
                        <Route
                          path="/settings"
                          element={
                            <ProtectedRoute requiredRoles={[AdminRole.SuperAdmin]}>
                              <SettingsPage />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/stats"
                          element={
                            <ProtectedRoute requiredRoles={[AdminRole.SuperAdmin]}>
                              <StatsPage />
                            </ProtectedRoute>
                          }
                        />
                        <Route path="/" element={<Navigate to="/cases" />} />
                      </Routes>
                    </Layout>
                  </UserProvider>
                }
              />
            </Routes>
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
