import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { AccountInfo } from "src/types/user";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Deposite } from "src/types/deposite";
import { ChangeEvent, useState } from "react";
import dayjs from "dayjs";
import { StyledTableCell as TableCell } from "src/components/common/table-cell";

interface CustomerTabDepositsProps {
  onConfirmDeposit: (orderId: string, amount: number, comment: string) => void;
  user: AccountInfo;
}

export function CustomerTabDeposits({
  user,
  onConfirmDeposit: onConfirmDeposit,
}: CustomerTabDepositsProps): JSX.Element {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(0);
  const [comment, setComment] = useState("");
  const [orderId, setOrderId] = useState("");

  const handleAmountChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAmount(parseFloat(event.target.value));
  };

  const handleCommentChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setComment(event.target.value);
  };

  const handleConfirmDeposit = async () => {
    await onConfirmDeposit(orderId, amount, comment);

    setAmount(0);
    setComment('');
    setOrderId('');
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleConfirmDeposit();
          }}
        >
          <Grid container spacing={1}>
            <Grid lg={7} xs={7}>
              <FormControl fullWidth size="small" required>
                <InputLabel>{t("USER.TRANSACTION_ID")}</InputLabel>
                <OutlinedInput
                  label={t("USER.TRANSACTION_ID")}
                  value={orderId}
                  onChange={(event => setOrderId(event.target.value.trim()))}
                />
              </FormControl>
            </Grid>
            <Grid lg={5} xs={5}>
              <FormControl fullWidth size="small" required>
                <InputLabel>{t("USER.TOPUP_AMOUNT")}</InputLabel>
                <OutlinedInput
                  type="number"
                  value={amount}
                  label={t("USER.TOPUP_AMOUNT")}
                  onChange={handleAmountChange}
                />
              </FormControl>
            </Grid>
            <Grid xs={9}>
              <FormControl fullWidth size="small" required>
                <InputLabel>{t("COMMON.COMMENT")}</InputLabel>
                <OutlinedInput
                  label={t("COMMON.COMMENT")}
                  multiline
                  maxRows="3"
                  value={comment}
                  onChange={handleCommentChange}
                />
              </FormControl>
            </Grid>
            <Grid lg={3} display={"flex"} justifyContent={"end"}>
              <Button
                fullWidth
                type="submit"
                disabled={!amount || !comment || !orderId}
                variant="contained"
              >
                {t("USER.CONFIRM_DEPOSIT")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid lg={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("COMMON.DATE")}</TableCell>
                <TableCell>{t("COMMON.AMOUNT")}</TableCell>
                <TableCell>{t("COMMON.STATUS")}</TableCell>
                <TableCell>{t("USER.TRANSACTION_ID")}</TableCell>
                <TableCell>{t("USER.PARTNER")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user?.deposits?.map((deposit: Deposite, index: number) => (
                <TableRow key={index}>
                  <TableCell>
                    {dayjs(deposit.created_at).utc(true).local().format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell>{deposit.amount}</TableCell>
                  <TableCell>{deposit.status}</TableCell>
                  <TableCell>{deposit.transaction_id}</TableCell>
                  <TableCell>{deposit.partner}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
