import { Category } from "src/types/cases";
import { ApiConfig } from "./api.config";
import { sendRequest } from "../api.services";

export const createCategory = async (category: Category): Promise<void> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/categories`, 'POST', category);

export const deteleteCategory = async (id: number): Promise<void> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/categories/${id}`, 'DELETE');

export const getCategories = async (): Promise<Category[]> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/admin_get_categories_order`, 'GET');

export const addOrUpdateCategory = async (category: Category): Promise<void> => 
    sendRequest(`${ApiConfig.baseAdminUrl}/admin_edit_categories_order`, 'POST', category);