import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { INews } from "src/types/news";
import { StyledTableCell as TableCell } from "src/components/common/table-cell";
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  TableContainer,
  Typography,
} from "@mui/material";
import { MagnifyingGlass, Pencil, Plus, ShareFat, Trash } from "@phosphor-icons/react";
import {
  addNews,
  deleteNews,
  getNews,
  updateNews,
} from "src/services/api/api.news.service";
import { NotificationDialog } from "./notification-dialog";
import { DeleteDialog } from "src/components/common/dialog-delete";
import { NewsLanguageType } from "src/constants/news.constants";
import { SendNotificationDialog } from "./notification-send-dialog";
import dayjs from "dayjs";
import { padding } from "@mui/system";

export function NotificaitonsTable(): React.JSX.Element {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSendDialog, setOpenSendDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<INews>({
    title: "",
    language: NewsLanguageType.RU,
    message_content: "",
    button_text: "",
    button_link: "",
    active: true,
  } as INews);
  const [page, setPage] = useState(0);
  const [rowsPerPageState, setRowsPerPageState] = useState(20);
  const [data, setData] = useState<INews[]>([]);
  const [filteredData, setFilteredData] = useState<INews[]>([]);

  const refresh = async () => {
    const data = await getNews();
    if (data) {
      setData(data);
      setFilteredData(data);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageState(parseInt(event.target.value));
    setPage(0);
  };

  const handleDialogUpdate = async (news?: INews) => {
    if (news) {
      const request = news.id ? updateNews : addNews;
      await request(news);
      await refresh();
    }

    setOpenDialog(false);
  };

  const handleDelete = async () => {
    await deleteNews(selectedItem.id);
    await refresh();
    setOpenDeleteDialog(false);
  };

  const visibleRows = useMemo(() => {
    return filteredData.slice(
      page * rowsPerPageState,
      page * rowsPerPageState + rowsPerPageState
    );
  }, [filteredData, page, rowsPerPageState]);

  const filter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    const newData = value
      ? data.filter((item: INews) =>
        item.title.toLowerCase().includes(value)
      )
      : data;
    setFilteredData(newData);
  };

  const onEdit = (item: INews) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const onDelete = (item: INews) => {
    setSelectedItem(item);
    setOpenDeleteDialog(true);
  };

  const onSend = (item: INews) => {
    setSelectedItem(item);
    setOpenSendDialog(true);
  };

  const onCloseSendDialog = async (needRefresh: boolean) => {
    setOpenSendDialog(false);
    if (needRefresh) {
      await refresh();
    }
  };

  const onAdd = () => {
    setSelectedItem({
      title: "",
      language: NewsLanguageType.RU,
      message_content: "",
      button_text: "",
      button_link: "",
      active: true,
    } as INews);
    setOpenDialog(true);
  };

  const table = () => {
    return (
      <Box sx={{ overflowX: "auto" }}>
        <TableContainer>
          <Table sx={{ minWidth: "800px" }}>
            <TableHead>
              <TableRow>
                <TableCell>{t("COMMON.ID")}</TableCell>
                <TableCell>{t("NOTIFICATIONS.TITLE")}</TableCell>
                <TableCell>{t("NOTIFICATIONS.TYPE")}</TableCell>
                <TableCell>{t("NOTIFICATIONS.ACTIVE")}</TableCell>
                <TableCell>{t("NOTIFICATIONS.PUBLISHED")}</TableCell>
                <TableCell>{t("NOTIFICATIONS.RECIPIENTS_COUNT")}</TableCell>
                <TableCell>{t("NOTIFICATIONS.UPDATE_DATE")}</TableCell>
                <TableCell sx={{ width: '8%' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.map((row) => {
                return (
                  <TableRow hover key={row.id} sx={{
                    backgroundColor: row.is_published ? 'var(--mui-palette-primary-100)' : 'initial',
                    '&:hover': {
                      backgroundColor: row.is_published ? 'var(--mui-palette-primary-200) !important' : 'initial',
                    },
                  }}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{t(`NOTIFICATIONS.${row.language.toUpperCase()}`)}</TableCell>
                    <TableCell>{row.active ? t('COMMON.YES') : t('COMMON.NO')}</TableCell>
                    <TableCell>{row.is_published ? t('COMMON.YES') : t('COMMON.NO')}</TableCell>
                    <TableCell>{row.available_for_everyone ? t('NOTIFICATIONS.AVAILABLE_FOR_EVERYONE_SHORT') : row.recipients_count}</TableCell>
                    <TableCell>
                      {dayjs(row.updated_at).utc(true).local().format("DD.MM.YYYY HH:mm")}
                    </TableCell>
                    <TableCell>
                      <Box sx={{display: 'flex', justifyContent: 'end'}}>
                        {!row.is_published && <IconButton onClick={() => onSend(row)}>
                          <ShareFat />
                        </IconButton>}
                        <IconButton onClick={() => onEdit(row)}>
                          <Pencil />
                        </IconButton>
                        <IconButton onClick={() => onDelete(row)}>
                          <Trash />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPageState}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("COMMON.ROWS_PER_PAGE")}
        />
      </Box>
    );
  };

  return (
    <Stack spacing={3}>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={3}
      >
        <Typography variant="h5">{t("NOTIFICATIONS.HEADER")}</Typography>
        <OutlinedInput
          size={"small"}
          defaultValue=""
          fullWidth
          placeholder={t("NOTIFICATIONS.NOTIFICATIONS")}
          startAdornment={
            <InputAdornment position="start">
              <MagnifyingGlass fontSize="var(--icon-fontSize-md)" />
            </InputAdornment>
          }
          sx={{ maxWidth: "500px" }}
          onChange={filter}
        />
        <div>
          <Button
            startIcon={<Plus fontSize="var(--icon-fontSize-md)" />}
            variant="contained"
            onClick={onAdd}
          >
            {t("COMMON.ADD")}
          </Button>
        </div>
      </Stack>
      {table()}
      <NotificationDialog
        item={selectedItem}
        openDialog={openDialog}
        onCloseDialog={handleDialogUpdate}
      ></NotificationDialog>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={handleDelete}
        itemName={selectedItem.title}
      ></DeleteDialog>
      <SendNotificationDialog onCloseDialog={onCloseSendDialog} openDialog={openSendDialog} item={selectedItem}></SendNotificationDialog>
    </Stack>
  );
}
