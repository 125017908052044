import { AccountInfo } from "src/types/user";
import { ApiConfig } from "./api.config";
import { sendRequest } from "../api.services";

export const getAccountInfo = async (): Promise<AccountInfo> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/get_account_info`, 'POST');

export const getUsers = async (): Promise<AccountInfo[]> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/users`, 'GET');

export const getUser = async (id: number): Promise<AccountInfo> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/users/${id}`, 'GET');

export const updateUser = async (user: AccountInfo): Promise<AccountInfo> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/users/${user.id}`, 'PUT', user);

export const topupBallance = async (userId: number, amount: number, comment: string): Promise<AccountInfo> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/users/${userId}/topup`, 'PUT', { amount, comment });

export const confirmDeposit = async (orderId: string, amount: number, comment: string): Promise<AccountInfo> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/deposit/confirm`, 'PUT', { order_id: orderId, amount, comment });