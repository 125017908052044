import { TableCell } from "@mui/material";

export const StyledTableCell = (props: any) => (
    <TableCell
      sx={{
        padding: '5px 5px',
        fontSize: '1rem'
      }}
      {...props}
    />
  );