

import * as React from 'react';
import { useUser } from 'src/hooks/use-user';
import { authClient } from 'src/services/auth.service';
import { useNavigate } from 'react-router-dom';

export interface AuthGuardProps {
  children: React.ReactNode;
}

export function AuthGuard({ children }: AuthGuardProps): React.JSX.Element | null {
  const { user, isLoading } = useUser();
  const [isChecking, setIsChecking] = React.useState<boolean>(true);
  const navigate = useNavigate();

  const checkPermissions = async (): Promise<void> => {
    if (isLoading) {
      return;
    }

    if (!user) {
      authClient.signOut();
      navigate('/login');
    }

    setIsChecking(false);
  };

  React.useEffect(() => {
    checkPermissions()
      .catch(() => {
        // noop
      });
  }, [user, isLoading]);

  if (isChecking) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
}
