import { useEffect, useState } from 'react';
import type { JSX } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { AccountInfo } from 'src/types/user';
import dayjs from 'dayjs';
import { Link } from '@mui/material';

export interface CustomerGeneralDataProps {
  userItem?: AccountInfo;
}

export const StyledListItem = (props: any) => (
  <ListItem
    {...props}
    sx={{
      padding: '0',
    }}
  />
);

export const StyledListItemText = (props: any) => (
  <ListItemText
    {...props}
  />
);

export function CustomerGeneralData({ userItem }: CustomerGeneralDataProps): JSX.Element {
  const { t } = useTranslation();
  const [user, setUser] = useState<AccountInfo>();

  useEffect(() => {
    setUser(userItem);
  }, [userItem]);

  return (
    <List sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: 10 }}>
      <StyledListItem>
        <StyledListItemText primary={t("USER.STEAM")} />
        <StyledListItemText sx={{ textAlign: 'right' }} ><Link href={user?.steam_account_info.profile_url} target="_blank">{user?.steam_id}</Link></StyledListItemText>
      </StyledListItem>
      <StyledListItem>
        <StyledListItemText primary={t('USER.BALANCE')} />
        <StyledListItemText primary={user?.balance} sx={{ textAlign: 'right' }} />
      </StyledListItem>
      <StyledListItem>
        <StyledListItemText primary={t('USER.HOLD')} />
        <StyledListItemText primary={user?.total_hold_amount} sx={{ textAlign: 'right' }} />
      </StyledListItem>
      <StyledListItem>
        <StyledListItemText primary={t('USER.RECHARGES')} />
        <StyledListItemText primary={user?.total_deposit_amount} sx={{ textAlign: 'right' }} />
      </StyledListItem>
      <StyledListItem>
        <StyledListItemText primary={t('USER.WITHDRAWS')} />
        <StyledListItemText primary={user?.total_withdraw_amount} sx={{ textAlign: 'right' }} />
      </StyledListItem>
      <StyledListItem>
        <StyledListItemText primary={t('USER.COUNTRY')} />
        <StyledListItemText primary={user?.country} sx={{ textAlign: 'right' }} />
      </StyledListItem>
      <StyledListItem>
        <StyledListItemText primary={t('USER.IP')} />
        <StyledListItemText primary={user?.ip} sx={{ textAlign: 'right' }} />
      </StyledListItem>
      <StyledListItem>
        <StyledListItemText primary={t('USER.GEOIP')} />
        <StyledListItemText primary={user?.geo_ip} sx={{ textAlign: 'right' }} />
      </StyledListItem>
      <StyledListItem>
        <StyledListItemText primary={t('USER.REGISTRATION_DATE')} />
        <StyledListItemText primary={dayjs(user?.created_at).utc(true).local().format('DD.MM.YYYY HH:mm')} sx={{ textAlign: 'right' }} />
      </StyledListItem>
      <StyledListItem>
        <StyledListItemText primary={t('USER.LAST_UPDATE')} />
        <StyledListItemText primary={dayjs(user?.updated_at).utc(true).local().format('DD.MM.YYYY HH:mm')} sx={{ textAlign: 'right' }} />
      </StyledListItem>
    </List>
  );
}
