import { INews, ISend } from "src/types/news";
import { ApiConfig } from "./api.config";
import { sendRequest } from "../api.services";

export const getNews = async (): Promise<INews[]> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/push_notifications`, 'GET');

export const addNews = async (news: INews): Promise<INews> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/push_notifications`, 'POST', news);

export const updateNews = async (news: INews): Promise<INews | undefined> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/push_notifications/${news.id}`, 'PUT', news);

export const deleteNews = async (id: number): Promise<void> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/push_notifications/${id}`, 'DELETE');

export const sendNotification = async (send: ISend): Promise<void> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/push_notifications/send`, 'POST', send);