

import React, { useCallback, useState } from 'react';
import type { JSX } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MagnifyingGlass as MagnifyingGlassIcon } from '@phosphor-icons/react/dist/ssr/MagnifyingGlass';
import type { Skinsback } from 'src/types/cases';
import { t } from 'i18next';
import { classImageUrl } from 'src/services/api/api.config';
import { getSkinsbackItems } from 'src/services/api/api.cases.service';

export interface SkinsAutocompleteProps {
  selectSkin: (item: Skinsback) => void;
}

export function SkinsAutocomplete({ selectSkin }: SkinsAutocompleteProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Skinsback[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [skinsData, setSkinsData] = useState<Skinsback[]>([]);

  const handleSearch = useCallback(async () => {
    if (searchTerm.length < 3) return;
    setLoading(true);
    const skins = await getSkinsbackItems(searchTerm);
    setSkinsData(skins);
    setPage(1);
    setOptions(skins?.slice(0, 20));
    setLoading(false);
    setOpen(true);
  }, [searchTerm, page]);

  const handleOpen = () => {
    if (options.length > 0) {
      setOpen(true); // Открываем меню только если есть результаты
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (item: Skinsback) => {
    selectSkin(item);
  }

  const handleScroll = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight * 0.95) {
      const newPage = page + 1;
      setPage(newPage);
      setOptions(skinsData.slice(0, newPage * 20));
    }
  };

  return (
    <Autocomplete
      size="small"
      freeSolo
      options={options}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={(event, value) => { handleSelect(value as Skinsback) }}
      getOptionLabel={(option: Skinsback | string) => (typeof option === 'string' ? option : option.name || '')}
      onInputChange={(event, newInputValue) => {
        setSearchTerm(newInputValue);
      }}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && searchTerm.length >= 3) {
          e.preventDefault();
          handleSearch().catch((error: unknown) => {
            if (error instanceof Error) {
              // eslint-disable-next-line no-console -- Allow for testing
              console.error('Search failed:', error.message);
            } else {
              // eslint-disable-next-line no-console  -- Allow for testing
              console.error('An unexpected error occurred', error);
            }
          });
        }
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id + option.name} style={{ display: 'flex', alignItems: 'center' }}>
          <img width={40} height={40} src={classImageUrl(option.classid)} alt={option.name} />
          <Typography fontWeight={'bold'} paddingLeft={1} paddingRight={1}>
            {option.name}
          </Typography>
          <Typography>{`Rarity: ${option.extra?.rarity} | Price: ${option.price}`}</Typography>
        </li>
      )}
      fullWidth
      ListboxProps={{ onScroll: handleScroll }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('CASES.SKIN_SEARCH')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearch}>
                  <MagnifyingGlassIcon />
                </IconButton>
                {loading && <CircularProgress size={24} />}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
