import React, { useEffect, useState } from "react";
import type { JSX } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { IBonus } from "src/types/bonus";
import { type Case } from "src/types/cases";
import { CampaignType, BonusType } from "src/constants/bonus.constants";
import Grid from "@mui/material/Unstable_Grid2";
import { getCases } from "src/services/api/api.cases.service";
import dayjs from "dayjs";

interface BonusDialogProps {
  item: IBonus;
  openDialog: boolean;
  onCloseDialog: (bonus?: IBonus) => void;
}

export function BonusDialog({
  item,
  openDialog,
  onCloseDialog,
}: BonusDialogProps): JSX.Element {
  const { t } = useTranslation();
  const [cases, setCases] = useState<Case[]>([]);
  const [bonus, setBonus] = useState<IBonus>(item);
  const [isTypeCase, setIsTypeCase] = useState(false);

  useEffect(() => {
    if (openDialog) {
      setBonus(item);
      setIsTypeCase(item?.type === BonusType.CASE);
    }
  }, [item, openDialog]);

  useEffect(() => {
    const fetchCases = async () => {
      const casesData = await getCases();
      setCases(casesData);
    };

    fetchCases();
  }, []);

  const onChange = (name: string, value: any) => {
    setBonus({ ...bonus, [name]: value });
  };

  const onSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;

    if (name === "type" && value === BonusType.CASE) {
      setBonus({
        ...bonus,
        type: value,
        bonus_amount_rub: 0,
        bonus_amount_usd: 0,
        bonus_amount_eur: 0,
        case_id: bonus.case_id,
      });
      setIsTypeCase(true);
    } else if (name === "type" && value === BonusType.BALANCE) {
      setBonus({
        ...bonus,
        type: value,
        case_id: undefined,
      });
      setIsTypeCase(false);
    } else {
      onChange(name, value);
    }
  };

  const onCheckboxChange = (event: React.SyntheticEvent, checked: boolean) => {
    const { name } = event.target as HTMLInputElement;
    onChange(name, checked);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity() || !dayjs(bonus?.active_date).isValid()) {
      form.reportValidity();
      return;
    }
    onCloseDialog(bonus);
  };

  const handleInvalid = (e: any) => {
    e.target.setCustomValidity(t("COMMON.FORM_VALIDATION_MESSAGE"));
  };
  const handleInput = (e: any) => {
    e.target.setCustomValidity("");
  };

  return (
    <Dialog maxWidth="lg" open={openDialog} onClose={() => onCloseDialog()}>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid lg={6} md={6} xs={6}>
                  <TextField
                    label={t("BONUSES.BONUS")}
                    value={bonus?.promo_code}
                    name="promo_code"
                    onChange={(e) =>
                      setBonus({
                        ...bonus,
                        promo_code: e.target.value.toUpperCase(),
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                    }}
                  />
                </Grid>
                <Grid
                  lg={2}
                  md={2}
                  xs={2}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bonus?.active}
                        onChange={onCheckboxChange}
                        name="active"
                      />
                    }
                    label={t("BONUSES.BONUS_ACTIVATION_STATUS")}
                  />
                </Grid>
                <Grid
                  lg={4}
                  md={4}
                  xs={4}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bonus?.new_user_activation_only}
                        onChange={onCheckboxChange}
                        name="new_user_activation_only"
                      />
                    }
                    label={t("BONUSES.NEW_USER_BONUS")}
                  />
                </Grid>
                <Grid lg={6} md={6} xs={12}>
                  <FormControl size="small" fullWidth margin="dense">
                    <InputLabel>
                      {t("BONUSES.BONUS_ACTIVATION_CAMPAIGN_TYPE")}
                    </InputLabel>
                    <Select
                      label={t("BONUSES.BONUS_ACTIVATION_CAMPAIGN_TYPE")}
                      onChange={onSelectChange}
                      name="department"
                      value={bonus?.department}
                    >
                      <MenuItem value={CampaignType.SMM}>
                        {t("PROMOCODES.CAMPAIGN_TYPE_SMM")}
                      </MenuItem>
                      <MenuItem value={CampaignType.PRODUCT}>
                        {t("PROMOCODES.CAMPAIGN_TYPE_PRODUCT")}
                      </MenuItem>
                      <MenuItem value={CampaignType.SUPPORT}>
                        {t("PROMOCODES.CAMPAIGN_TYPE_SUPPORT")}
                      </MenuItem>
                      <MenuItem value={CampaignType.AFFILIATE}>
                        {t("PROMOCODES.CAMPAIGN_TYPE_AFFILIATE")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid lg={6} md={6} xs={12}>
                  <FormControl size="small" fullWidth margin="dense">
                    <InputLabel> {t("BONUSES.BONUS_TYPE")}</InputLabel>
                    <Select
                      label={t("BONUSES.BONUS_TYPE")}
                      onChange={onSelectChange}
                      name="type"
                      value={bonus?.type || BonusType.BALANCE}
                    >
                      <MenuItem value={BonusType.BALANCE}>
                        {t("BONUSES.BONUS_TYPE_DEPOSIT")}
                      </MenuItem>
                      <MenuItem value={BonusType.CASE}>
                        {t("BONUSES.BONUS_TYPE_CASE")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid lg={12} md={12} xs={12}>
                  <FormControl fullWidth margin="dense" size="small">
                    <Autocomplete
                      options={cases}
                      getOptionLabel={(option) => option.name}
                      value={
                        cases.find(
                          (caseItem) => caseItem.id === bonus?.case_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setBonus({
                          ...bonus,
                          case_id: newValue ? newValue.id : undefined,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("BONUSES.BONUS_LINK")}
                          size="small"
                          margin="dense"
                          fullWidth
                          required={isTypeCase}
                          inputProps={{
                            ...params.inputProps,
                            onInvalid: handleInvalid,
                            onInput: handleInput,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  lg={6}
                  md={4}
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <DatePicker
                    label={t("BONUSES.BONUS_ACTIVE_DATE")}
                    value={dayjs(bonus?.active_date)}
                    onChange={(value: any) =>
                      setBonus({ ...bonus, active_date: value })
                    }
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        required: true,
                        onInvalid: handleInvalid,
                        onInput: handleInput,
                      },
                    }}
                  />
                </Grid>
                <Grid lg={6} md={4} xs={12}>
                  <TextField
                    type="number"
                    label={t("BONUSES.BONUS_ACTIVATION_LIMIT")}
                    value={bonus?.activation_limit || ""}
                    placeholder="0"
                    onChange={(e) => {
                      setBonus({
                        ...bonus,
                        activation_limit: parseInt(e.target.value),
                      });
                    }}
                    fullWidth
                    margin="none"
                    size="small"
                    required
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                      min: 0,
                    }}
                  />
                </Grid>
                <Grid lg={4} md={4} xs={12}>
                  <TextField
                    disabled={isTypeCase}
                    type="number"
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                      min: 0,
                    }}
                    label={t("BONUSES.BONUS_AMOUNT_RUB")}
                    value={bonus?.bonus_amount_rub || ""}
                    placeholder="0"
                    onChange={(e) =>
                      setBonus({
                        ...bonus,
                        bonus_amount_rub: parseFloat(e.target.value),
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required={!isTypeCase}
                  />
                </Grid>
                <Grid lg={4} md={4} xs={12}>
                  <TextField
                    disabled={isTypeCase}
                    type="number"
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                      min: 0,
                      step: 0.1,
                    }}
                    label={t("BONUSES.BONUS_AMOUNT_USD")}
                    value={bonus?.bonus_amount_usd || ""}
                    placeholder="0"
                    onChange={(e) =>
                      setBonus({
                        ...bonus,
                        bonus_amount_usd: parseFloat(e.target.value),
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required={!isTypeCase}
                  />
                </Grid>
                <Grid lg={4} md={4} xs={12}>
                  <TextField
                    disabled={isTypeCase}
                    type="number"
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                      min: 0,
                      step: 0.1,
                    }}
                    label={t("BONUSES.BONUS_AMOUNT_EUR")}
                    placeholder="0"
                    value={bonus?.bonus_amount_eur || ""}
                    onChange={(e) =>
                      setBonus({
                        ...bonus,
                        bonus_amount_eur: parseFloat(e.target.value),
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required={!isTypeCase}
                  />
                </Grid>
                <Grid lg={4} md={4} xs={12}>
                  <TextField
                    type="number"
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                      min: 0,
                    }}
                    label={t("BONUSES.BONUS_MIN_DEPOSIT_RUB")}
                    value={bonus?.min_deposit_rub || ""}
                    placeholder="0"
                    onChange={(e) =>
                      setBonus({
                        ...bonus,
                        min_deposit_rub: parseFloat(e.target.value),
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                  />
                </Grid>
                <Grid lg={4} md={4} xs={12}>
                  <TextField
                    type="number"
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                      min: 0,
                    }}
                    label={t("BONUSES.BONUS_MIN_DEPOSIT_USD")}
                    value={bonus?.min_deposit_usd || ""}
                    placeholder="0"
                    onChange={(e) =>
                      setBonus({
                        ...bonus,
                        min_deposit_usd: parseFloat(e.target.value),
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                  />
                </Grid>
                <Grid lg={4} md={4} xs={12}>
                  <TextField
                    type="number"
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                      min: 0,
                    }}
                    label={t("BONUSES.BONUS_MIN_DEPOSIT_EUR")}
                    value={bonus?.min_deposit_eur || ""}
                    placeholder="0"
                    onChange={(e) =>
                      setBonus({
                        ...bonus,
                        min_deposit_eur: parseFloat(e.target.value),
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => onCloseDialog()}>
            {t("COMMON.CANCEL")}
          </Button>
          <Button variant="contained" type="submit">
            {t("COMMON.SAVE")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
