import type { NavItemConfig } from 'src/types/nav';
import { Gear, Gift, ListBullets, Notification, SealPercent } from '@phosphor-icons/react';
import type { Icon } from '@phosphor-icons/react/dist/lib/types';
import { BriefcaseMetal } from '@phosphor-icons/react/dist/ssr';
import { ChartPie as ChartPieIcon } from '@phosphor-icons/react/dist/ssr/ChartPie';
import { GearSix as GearSixIcon } from '@phosphor-icons/react/dist/ssr/GearSix';
import { PlugsConnected as PlugsConnectedIcon } from '@phosphor-icons/react/dist/ssr/PlugsConnected';
import { User as UserIcon } from '@phosphor-icons/react/dist/ssr/User';
import { Users as UsersIcon } from '@phosphor-icons/react/dist/ssr/Users';
import { XSquare } from '@phosphor-icons/react/dist/ssr/XSquare';
import { AdminRole } from 'src/types/admin';

export const Paths = {
  home: '/',
  regular: {
    account: '/account',
    users: '/users',
    news: '/news',
    categories: '/categories',
    promocodes: '/promocodes',
    bonuses: '/bonuses',
    cases: '/cases',
  },
  superadmin: {
    stats: '/stats',
    settings: '/settings',
  },
  errors: { notFound: '/errors/not-found' },
} as const;

export const NavIcons = {
  'chart-pie': ChartPieIcon,
  'gear-six': GearSixIcon,
  'plugs-connected': PlugsConnectedIcon,
  'x-square': XSquare,
  'seal-percent': SealPercent,
  'briefcase-metal': BriefcaseMetal,
  'list-bullets': ListBullets,
  'gift': Gift,
  'notificaitons': Notification,
  user: UserIcon,
  users: UsersIcon,
  settings: Gear,
} as Record<string, Icon>;

export const NavItems = [
  { key: `stats`, title: 'NAVBAR.STATS', href: Paths.superadmin.stats, icon: 'chart-pie', requiredRole: AdminRole.SuperAdmin },
  { key: 'cases', title: 'NAVBAR.CASES', href: Paths.regular.cases, icon: 'briefcase-metal' },
  { key: 'categories', title: 'NAVBAR.CATEGORIES', href: Paths.regular.categories, icon: 'list-bullets' },
  { key: 'promocodes', title: 'NAVBAR.PROMOCODES', href: Paths.regular.promocodes, icon: 'seal-percent' },
  { key: 'bonuses', title: 'NAVBAR.BONUSES', href: Paths.regular.bonuses, icon: 'gift' },
  { key: 'users', title: 'NAVBAR.USERS', href: Paths.regular.users, icon: 'users' },
  { key: 'notificaitons', title: 'NAVBAR.NOTIFICATIONS', href: Paths.regular.news, icon: 'notificaitons' },
  { key: 'settings', title: 'NAVBAR.SETTINGS', href: Paths.superadmin.settings, icon: 'settings', requiredRole: AdminRole.SuperAdmin },
] satisfies NavItemConfig[];
