import { AdminUser, LoginData } from "src/types/admin";
import { sendRequest } from "../api.services";
import { ApiConfig } from "./api.config";

export const adminLogin = async (loginData: LoginData): Promise<{ status: string; jwt: string }> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/login`, 'POST', loginData);

export const getAdminUsers = async (): Promise<{ status: string; users: AdminUser[] }> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/admin_users`, 'GET');

export const createAdminUser = async (userData: AdminUser): Promise<{ status: string; message: string }> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/admin_users`, 'POST', userData);

export const updateAdminUser = async (adminId: number, userData: AdminUser): Promise<{ status: string; message: string }> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/admin_users/${adminId}`, 'PUT', userData);

export const getAdminProfile = async (): Promise<AdminUser> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/admin_users/profile`, 'GET');