import { IBonus } from "src/types/bonus";
import { ApiConfig } from "./api.config";
import { sendRequest } from "../api.services";

export const getBonuses = async (): Promise<IBonus[]> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/bonus_promo_codes`, 'GET');

export const addBonus = async (bonus: IBonus): Promise<IBonus> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/bonus_promo_codes`, 'POST', bonus)

export const updateBonus = async (bonus: IBonus): Promise<IBonus | undefined> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/bonus_promo_codes/${bonus.id}`, 'PUT', bonus)

export const deleteBonus = async (id: number): Promise<void> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/bonus_promo_codes/${id}`, 'DELETE');