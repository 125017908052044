import { adminLogin, getAdminProfile } from "./api/api.admin.service";
import { AdminUser } from "src/types/admin";

class AuthService {
  async signIn(emailOrSteamId: string, password: string): Promise<{ error?: string }> {
    const user = await adminLogin({ login: emailOrSteamId, password });
    localStorage.setItem('jwt', user.jwt);
    return {};
  }

  async getUserProfile(): Promise<{ data?: AdminUser | null; error?: string }> {

    const jwt = this.getToken();

    if (jwt) {
      const accountInfo = await getAdminProfile();
      return { data: accountInfo };
    }

    return { error: 'Unauthorized' };
  }

  async signOut() {
    localStorage.removeItem('jwt');
  }

  getToken(): string | null {
    return localStorage.getItem('jwt');
  }
}

export const authClient = new AuthService();
