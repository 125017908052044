import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import TabContent24h from "./24h/24h";
import TabContentFull from "./full/full";
import TabContentGeneral from "./general/general";

export default function StatsPage(): React.JSX.Element {
  const { t } = useTranslation();
  const [tab, setTab] = useState("general");

  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          onChange={(e, value) => setTab(value)}
          aria-label="lab API tabs example"
        >
          <Tab label={t("STATS.GENERAL_TAB")} value="general" />
          <Tab label={t("STATS.24H")} value="24h" />
          <Tab label={t("STATS.FULL_HISTORY")} value="charts" />
        </TabList>
      </Box>
      <TabPanel value="general" sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <TabContentGeneral />
      </TabPanel>
      <TabPanel value="24h" sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <TabContent24h />
      </TabPanel>
      <TabPanel value="charts" sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <TabContentFull />
      </TabPanel>
    </TabContext>
  );
}
