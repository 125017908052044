import React, { useEffect, useState } from "react";
import type { JSX } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { INews } from "src/types/news";
import { NewsLanguageType } from "src/constants/news.constants";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";

interface NewsDialogProps {
  item: INews;
  openDialog: boolean;
  onCloseDialog: (news?: INews) => void;
}

export function NotificationDialog({
  item,
  openDialog,
  onCloseDialog,
}: NewsDialogProps): JSX.Element {
  const { t } = useTranslation();
  const [news, setNews] = useState<INews>(item);

  useEffect(() => {
    if (openDialog) {
      setNews(item);
    }
  }, [item, openDialog]);

  const onChange = (name: string, value: any) => {
    setNews({ ...news, [name]: value });
  };

  const onSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const onCheckboxChange = (event: React.SyntheticEvent, checked: boolean) => {
    const { name } = event.target as HTMLInputElement;
    onChange(name, checked);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity() || !dayjs(news?.created_at).isValid()) {
      form.reportValidity();
      return;
    }
    onCloseDialog(news);
  };

  const handleInvalid = (e: any) => {
    e.target.setCustomValidity(t("COMMON.FORM_VALIDATION_MESSAGE"));
  };
  const handleInput = (e: any) => {
    e.target.setCustomValidity("");
  };

  return (
    <Dialog maxWidth="lg" open={openDialog} onClose={() => onCloseDialog()}>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid lg={6} md={6} xs={6}>
                  <TextField
                    label={t("NOTIFICATIONS.TITLE")}
                    value={news?.title}
                    name="title"
                    onChange={(e) =>
                      setNews({
                        ...news,
                        title: e.target.value,
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                    }}
                  />
                </Grid>
                <Grid
                  lg={3}
                  md={3}
                  xs={3}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={news?.active}
                        onChange={onCheckboxChange}
                        name="active"
                      />
                    }
                    label={t("NOTIFICATIONS.ACTIVE")}
                  />
                </Grid>
                <Grid lg={3} md={3} xs={3}>
                  <FormControl size="small" fullWidth margin="dense">
                    <InputLabel> {t("NOTIFICATIONS.TYPE")}</InputLabel>
                    <Select
                      label={t("NOTIFICATIONS.TYPE")}
                      onChange={onSelectChange}
                      name="language"
                      value={news?.language || NewsLanguageType.RU}
                    >
                      <MenuItem value={NewsLanguageType.RU}>
                        {t(`NOTIFICATIONS.${NewsLanguageType.RU.toUpperCase()}`)}
                      </MenuItem>
                      <MenuItem value={NewsLanguageType.EN}>
                        {t(`NOTIFICATIONS.${NewsLanguageType.EN.toUpperCase()}`)}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid lg={12} md={12} xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <label
                      htmlFor="news-text"
                      style={{ marginBottom: "8px", display: "block" }}
                    >
                      {t("NOTIFICATIONS.TEXT")}:
                    </label>
                    <textarea
                      id="news-text"
                      rows={10}
                      value={news?.message_content}
                      onChange={(e) =>
                        setNews({
                          ...news,
                          message_content: e.target.value,
                        })
                      }
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderColor: "#c4c4c4",
                        borderRadius: "10px",
                        overflow: "auto",
                        resize: "vertical",
                        fontSize: "14px",
                        fontFamily: "inherit",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid lg={6} md={6} xs={12}>
                  <TextField
                    label={t("NOTIFICATIONS.BUTTON_TEXT")}
                    value={news?.button_text}
                    name="button_text"
                    onChange={(e) =>
                      setNews({
                        ...news,
                        button_text: e.target.value,
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                    }}
                  />
                </Grid>
                <Grid lg={6} md={6} xs={12}>
                  <TextField
                    label={t("NOTIFICATIONS.BUTTON_LINK")}
                    value={news?.button_link}
                    name="button_link"
                    onChange={(e) =>
                      setNews({
                        ...news,
                        button_link: e.target.value,
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => onCloseDialog()}>
            {t("COMMON.CANCEL")}
          </Button>
          <Button variant="contained" type="submit">
            {t("COMMON.SAVE")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
