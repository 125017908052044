import React, { useState } from 'react';
import type { JSX } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Upload } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { VisuallyHiddenInput } from 'src/components/common/hidden-input';
import { imageUrl } from 'src/services/api/api.config';

interface CaseDialogFormImageProps {
  caseId: number;
  imagePath: string;
  onImageUploaded: (imageForm: FormData) => void;
}

export function CaseDialogFormImage({ caseId, imagePath, onImageUploaded: uploadImageSrc }: CaseDialogFormImageProps): JSX.Element {
  const { t } = useTranslation();
  const [imageSrc, setImageSrc] = useState<string>(imagePath ? imageUrl(imagePath) : '/assets/no-image.png');

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type === 'image/webp') {
      const reader = new FileReader();
      reader.onload = () => {
        const imageSrc = reader.result as string;
        if (imageSrc && imageSrc !== imagePath) {
          const formData = new FormData();
          formData.append('image', file);
          formData.append('case_id', caseId.toString());
          setImageSrc(imageSrc);
          uploadImageSrc(formData);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <Stack spacing={2} sx={{ alignItems: 'center' }}>
      <div style={{ backgroundColor: 'var(--mui-palette-neutral-950)', borderRadius: 20 }}>
        <Avatar src={imageSrc} sx={{ height: '220px', width: '220px' }} variant="rounded" />
      </div>
      <Button sx={{ width: 220 }} size="small" component="label" variant="contained" tabIndex={-1} startIcon={<Upload />}>
        {t('COMMON.UPLOAD_IMAGE')}
        <VisuallyHiddenInput hidden={true} type="file" onChange={handleFileChange} accept="image/webp" />
      </Button>
    </Stack>
  );
}
