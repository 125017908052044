import { useEffect, useMemo, useState } from "react";
import { getGeneral } from "src/services/api/api.stats";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { AdminStats } from "src/types/stats";

export default function TabContentGeneral({
}): React.JSX.Element {
  const { t } = useTranslation();

  const [generalData, setGeneralData] = useState<AdminStats>();

  useEffect(() => {
    getGeneral().then((data: AdminStats) => setGeneralData(data));
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid lg={4} >
        <Card sx={{ height: '100%', backgroundColor: 'var(--mui-palette-primary-100)' }}>
          <CardHeader title={<Typography variant="h6">{t("STATS.GENERAL")}</Typography>} />
          <CardContent>
            <Typography>{t("STATS.SUM_BALANCES")}: {generalData?.general.sum_balances}</Typography>
            <Typography>{t("STATS.SUM_OF_DEPOSITS")}: {generalData?.general.sum_of_deposits}</Typography>
            <Typography>{t("STATS.TOTAL_PAYERS")}: {generalData?.general.total_payers}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid lg={4} >
        <Card sx={{ height: '100%', backgroundColor: 'var(--mui-palette-primary-100)' }}>
          <CardHeader title={<Typography variant="h6">{t("STATS.BETS")}</Typography>} />
          <CardContent>
            <Typography>{t("STATS.SUM_OF_BETS")}: {generalData?.bets.sum_of_bets}</Typography>
            <Typography>{t("STATS.SUM_OF_WINS")}: {generalData?.bets.sum_of_wins}</Typography>
            <Typography>{t("STATS.PROFIT")}: {generalData?.bets.profit}</Typography>
            <Typography>{t("STATS.RTP")}: {generalData?.bets.rtp}</Typography>
            <Typography>{t("STATS.RTP_PERCENTAGE")}: {generalData?.bets.rtp_percentage}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid lg={4} >
        <Card sx={{ height: '100%', backgroundColor: 'var(--mui-palette-primary-100)' }}>
          <CardHeader title={<Typography variant="h6">{t("STATS.CASES")}</Typography>} />
          <CardContent>
            <Typography>{t("STATS.SUM_PROFIT_FROM_OPENING")}: {generalData?.cases.sum_profit_from_opening}</Typography>
            <Typography>{t("STATS.SUM_VALUE_OF_DROPPED_ITEMS")}: {generalData?.cases.sum_value_of_dropped_items}</Typography>
            <Typography>{t("STATS.COUNT_OF_OPENINGS")}: {generalData?.cases.count_of_openings}</Typography>
            <Typography>{t("STATS.PROFIT")}: {generalData?.cases.profit}</Typography>
            <Typography>{t("STATS.RTP_PERCENTAGE")}: {generalData?.cases.rtp_percentage}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid lg={6} >
        <Card sx={{ height: '100%', backgroundColor: 'var(--mui-palette-primary-100)' }}>
          <CardHeader title={<Typography variant="h6">{t("STATS.CONTRACTS")}</Typography>} />
          <CardContent>
            <Typography>{t("STATS.SUM_PROFIT_FROM_CONTRACTS")}: {generalData?.contracts.sum_profit_from_contracts}</Typography>
            <Typography>{t("STATS.SUM_VALUE_OF_COMPLETED_CONTRACTS")}: {generalData?.contracts.sum_value_of_completed_contracts}</Typography>
            <Typography>{t("STATS.COUNT_OF_CONTRACTS")}: {generalData?.contracts.count_of_contracts}</Typography>
            <Typography>{t("STATS.PROFIT")}: {generalData?.contracts.profit}</Typography>
            <Typography>{t("STATS.RTP")}: {generalData?.contracts.rtp}</Typography>
            <Typography>{t("STATS.ACTUAL_RTP_PERCENTAGE")}: {generalData?.contracts.actual_rtp_percentage}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid lg={6} >
        <Card sx={{ height: '100%', backgroundColor: 'var(--mui-palette-primary-100)' }}>
          <CardHeader title={<Typography variant="h6">{t("STATS.UPGRADES")}</Typography>} />
          <CardContent>
            <Typography>{t("STATS.SUM_PROFIT_FROM_UPGRADES")}: {generalData?.upgrades.sum_profit_from_upgrades}</Typography>
            <Typography>{t("STATS.SUM_VALUE_AT_UPGRADE")}: {generalData?.upgrades.sum_value_at_upgrade}</Typography>
            <Typography>{t("STATS.COUNT_OF_UPGRADES")}: {generalData?.upgrades.count_of_upgrades}</Typography>
            <Typography>{t("STATS.PROFIT")}: {generalData?.upgrades.profit}</Typography>
            <Typography>{t("STATS.RTP")}: {generalData?.upgrades.rtp}</Typography>
            <Typography>{t("STATS.ACTUAL_RTP_PERCENTAGE")}: {generalData?.upgrades.actual_rtp_percentage}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
