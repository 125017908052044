import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from './user-context';

export function useAuthorization(requiredRoles: string[]): boolean {
    const context = useContext(UserContext);

    if (!context) {
        throw new Error('useAuthorization must be used within a UserProvider');
    }

    const {user, isLoading } = context;

    if (isLoading) {
        return false;
    }

    return requiredRoles.some(rr => rr === user?.role);
}

interface ProtectedRouteProps {
    children: React.ReactNode;
    requiredRoles: string[];
}

export function ProtectedRoute({ children, requiredRoles }: ProtectedRouteProps): React.JSX.Element {
    const isAuthorized = useAuthorization(requiredRoles);

    if (!isAuthorized) {
        return <Navigate to="/" />;
    }

    return <>{children}</>;
}