import React, { useEffect, useMemo, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { getUsers } from "src/services/api/api.user.service";
import { AccountInfo } from "src/types/user";
import { StyledTableCell as TableCell } from "src/components/common/table-cell";
import {
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TablePagination,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { MagnifyingGlass, Pencil } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { Paths } from "../layout/config";

export function CustomersTable(): React.JSX.Element {
  const { t } = useTranslation();
  const [users, setUsers] = useState<AccountInfo[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPageState, setRowsPerPageState] = useState(20);
  const [filteredData, setFilteredData] = useState<AccountInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getUsers().then((data) => {
      setUsers(data);
      setFilteredData(data);
      setLoading(false);
    });
  }, []);

  const visibleRows = useMemo(() => {
    return filteredData.slice(
      page * rowsPerPageState,
      page * rowsPerPageState + rowsPerPageState
    );
  }, [filteredData, page, rowsPerPageState]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageState(parseInt(event.target.value));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    const newData = value
      ? users.filter((user: AccountInfo) =>
        value
          ? user.steam_account_info?.persona_name
            ?.toLowerCase()
            .includes(value) ||
          user.steam_id.includes(value) ||
          user.geo_ip.includes(value)
          : true
      )
      : users;
    setFilteredData(newData);
  };

  return (
    <Stack spacing={1}>
      <OutlinedInput
        defaultValue=""
        fullWidth
        placeholder={t("USER.SEARCH_PLACEHOLDER")}
        type="search"
        size="small"
        startAdornment={
          <InputAdornment position="start">
            <MagnifyingGlass fontSize="var(--icon-fontSize-md)" />
          </InputAdornment>
        }
        sx={{ maxWidth: "500px" }}
        onChange={handleSearchChange}
      />
      <Card>
        <Box sx={{ overflowX: "auto" }}>
          <Table sx={{ minWidth: "800px" }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t("USER.NAME")}</TableCell>
                <TableCell>Steam ID</TableCell>
                <TableCell>{t("USER.SING_UP")}</TableCell>
                <TableCell>{t("USER.GEOIP")}</TableCell>
                <TableCell>{t("USER.COUNTRY")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array.from(new Array(5)).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton variant="circular" width={30} height={30} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Stack direction={"row"} justifyContent={"end"}>
                        <Skeleton variant="circular" width={24} height={24} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
                : visibleRows.map((user) => {
                  return (
                    <TableRow hover key={user.id}>
                      <TableCell>
                        <Avatar
                          src={user.steam_account_info.avatar}
                          sx={{ width: 30, height: 30 }}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {user.steam_account_info.persona_name}
                        </Typography>
                      </TableCell>
                      <TableCell>{user.steam_id}</TableCell>
                      <TableCell>
                        {dayjs(user.created_at).utc(true).local().format("MMM D, YYYY")}
                      </TableCell>
                      <TableCell>{user.geo_ip ?? ""}</TableCell>
                      <TableCell>{user.country ?? ""}</TableCell>
                      <TableCell>
                        <Stack direction={"row"} justifyContent={"end"}>
                          <IconButton
                            onClick={() =>
                              navigate(`${Paths.regular.users}/${user.id}`)
                            }
                          >
                            <Pencil />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPageState}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("COMMON.ROWS_PER_PAGE")}
        />
      </Card>
    </Stack>
  );
}
