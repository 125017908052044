import React, { useEffect, useState } from "react";
import type { JSX } from "react";
import {
  getCase,
  openCase,
  resetCaseLogs,
} from "src/services/api/api.cases.service";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import type { Case } from "src/types/cases";
import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { StyledTableCell as TableCell } from "src/components/common/table-cell";
import { X } from "@phosphor-icons/react";
import { TestProgress } from "src/components/tests/test-progress";

interface CaseTestDialogProps {
  item: Case;
  openDialog: boolean;
  onCloseDialog: () => void;
}

export function CaseTestDialog({
  item,
  openDialog,
  onCloseDialog,
}: CaseTestDialogProps): JSX.Element {
  const { t } = useTranslation();
  const [amount, setAmount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [testItem, setTestItem] = useState<Case>({} as Case);
  const [successCount, setSuccessCount] = useState<number>(0);
  const [failedCount, setFailedCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    setTestItem(item);
    setAmount(0);
    onCleanTests();
  }, [item]);

  const onCleanTests = () => {
    setTotalCount(0);
    setSuccessCount(0);
    setFailedCount(0);
  };
  const refresh = async (func: () => Promise<void>) => {
    setLoading(true);

    await func();

    const caseItem = await getCase(item.id);
    if (caseItem) {
      setTestItem(caseItem);
    }

    setLoading(false);
  };

  const onTestRun = async () => {
    setSuccessCount(0);
    setFailedCount(0);
    setTotalCount(amount);

    const processResults = (results: any[]) => {
      results.forEach((result) => {
        if (result) {
          if (result.result === "success") {
            setSuccessCount((prev) => prev + 1);
          } else if (result.result === "failed") {
            setFailedCount((prev) => prev + 1);
          }
        }
      });
    };

    refresh(async () => {
      const batchSize = 10;
      const batches = Math.ceil(amount / batchSize);

      for (let batch = 0; batch < batches; batch++) {
        const start = batch * batchSize;
        const end = Math.min(start + batchSize, amount);
        const promises = [];

        for (let i = start; i < end; i++) {
          promises.push(openCase(item.id));
        }

        const results = await Promise.all(promises);
        processResults(results);
      }
    });
  };

  const onResetLogs = async () => {
    setAmount(0);
    onCleanTests();
    refresh(async () => {
      await resetCaseLogs(item.id);
    });
  };

  return (
    <Dialog open={openDialog} onClose={onCloseDialog} maxWidth="md">
      <DialogTitle>
        {t("CASES.TEST_TITLE")}: {item.name}
        <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} marginTop={1}>
          <Grid lg={6}>
            <TextField
              label={t("CASES.TEST_AMOUNT")}
              type="number"
              value={amount}
              onChange={(value) => setAmount(parseInt(value.target.value))}
              size="small"
              required
              fullWidth
            />
          </Grid>
          <Grid lg={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={onTestRun}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                <>{t("CASES.RUN_TEST")}</>
              )}
            </Button>
          </Grid>
          <Grid lg={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={onResetLogs}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                <>{t("CASES.RESET_LOGS")}</>
              )}
            </Button>
          </Grid>
          <Grid lg={12} xs={12} md={12}>
            <TestProgress
              passed={successCount}
              failed={failedCount}
              total={totalCount}
            />
          </Grid>
          <Grid lg={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid lg={5}>
                    <Typography>{t("CASES.OPENING_PRICE")}:</Typography>
                  </Grid>
                  <Grid lg={7}>
                    <Typography fontWeight={"bold"}>
                      {testItem?.price_of_opening}
                    </Typography>
                  </Grid>
                  <Grid lg={5}>
                    <Typography>{t("CASES.EXPECTED_RTP")}: </Typography>
                  </Grid>
                  <Grid lg={7}>
                    <Typography fontWeight={"bold"}>
                      {(100 * testItem?.rtp).toFixed(2)}%
                    </Typography>
                  </Grid>
                  <Divider sx={{ width: "100%" }} />
                  <Grid lg={5}>
                    <Typography>{t("CASES.TOTAL_OPENING")}:</Typography>
                  </Grid>
                  <Grid lg={7}>
                    <Typography fontWeight={"bold"}>
                      {testItem?.count_of_openings}
                    </Typography>
                  </Grid>
                  <Grid lg={5}>
                    <Typography>{t("CASES.TOTAL_DEPOSIT")}:</Typography>
                  </Grid>
                  <Grid lg={7}>
                    <Typography fontWeight={"bold"}>
                      {testItem?.sum_profit_from_opening?.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid lg={5}>
                    <Typography>{t("CASES.TOTAL_DROPPPED")}:</Typography>
                  </Grid>
                  <Grid lg={7}>
                    <Typography fontWeight={"bold"}>
                      {testItem?.sum_value_of_dropped_items?.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid lg={5}>
                    <Typography>{t("CASES.TOTAL_PROFIT")}:</Typography>
                  </Grid>
                  <Grid lg={7}>
                    <Typography fontWeight={"bold"}>
                      {(
                        testItem?.sum_profit_from_opening -
                        testItem?.sum_value_of_dropped_items
                      )?.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid lg={5}>
                    <Typography>{t("CASES.ACTUAL_RTP")}:</Typography>
                  </Grid>
                  <Grid lg={7}>
                    <Typography fontWeight={"bold"}>
                      {(testItem?.actual_rtp)?.toFixed(4)}%
                    </Typography>
                  </Grid>
                  <Divider sx={{ width: "100%" }} />
                  <Grid lg={5}>
                    <Typography>{t("CASES.SKINS_DISPERTION")}:</Typography>
                  </Grid>
                  <Grid lg={12}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("CASES.SKIN")}</TableCell>
                            <TableCell>{t("CASES.PRICE_AT_RECEIVING")}</TableCell>
                            <TableCell>{t("CASES.TEST_AMOUNT")}</TableCell>
                            <TableCell>{t("CASES.TOTAL_VALUE")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {testItem?.aggregated_drop?.map((drop) => (
                            <TableRow key={drop.market_hash_name + drop.price_at_receiving}>
                              <TableCell>{drop.market_hash_name}</TableCell>
                              <TableCell>{drop.price_at_receiving}</TableCell>
                              <TableCell>{drop.count}</TableCell>
                              <TableCell>{drop.summ_value_of_skins}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CaseTestDialog;
