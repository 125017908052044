import React, { useEffect, useState } from "react";
import type { JSX } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { use } from "i18next";
import { useTranslation } from "react-i18next";

interface TestProgressProps {
  passed: number;
  failed: number;
  total: number;
}

export function TestProgress({
  passed,
  failed,
  total,
}: TestProgressProps): JSX.Element {
  const { t } = useTranslation();
  const [backgroundColor, setBackgroundColor] = useState("info.light");
  const [progressBarColor, setProgressBarColor] = useState("info.light");
  const successPercentage = total > 0 ? (passed / total) * 100 : 0;

  useEffect(() => {
    if (!!total) {
      setBackgroundColor("error.light");
      setProgressBarColor("success.light");
    } else {
      setBackgroundColor("info.light");
      setProgressBarColor("info.light");
    }
  }, [total]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <Typography variant="body1">
          {t("COMMON.PASSED")}: {passed} / {t("COMMON.FAILED")}: {failed}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={successPercentage}
          sx={{
            height: 10,
            borderRadius: 5,
            backgroundColor: backgroundColor,
            "& .MuiLinearProgress-bar": {
              backgroundColor: progressBarColor,
            },
          }}
        />
      </Box>
    </Box>
  );
}
