export enum BonusType {
  BALANCE = 'balance',
  CASE = 'case',
}

export enum CampaignType {
  SMM = 'smm',
  SUPPORT = 'support',
  PRODUCT = 'product',
  AFFILIATE = 'affiliate',
}