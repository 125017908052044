import type { JSX } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { AccountInfo } from "src/types/user";
import { CustomerGeneralData } from "./customer-general-data";

interface CustomerGeneralProps {
  user?: AccountInfo;
}

export function CustomerGeneral({ user }: CustomerGeneralProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Stack spacing={10} sx={{ alignItems: "center" }} display={'flex'} direction={'row'}>
          <Box sx={{ width: "100%" }}>
            <Typography variant="h5">
              {user?.steam_account_info.persona_name}
            </Typography>
            <CustomerGeneralData userItem={user} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '30%' }}>
            <Avatar
              src={user?.steam_account_info.avatar_medium}
              sx={{ height: "125px", width: "125px"}}
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}
