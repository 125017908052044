import React, { useEffect, useState } from "react";
import type { JSX } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";

import type { Case, Category } from "src/types/cases";

import { CaseDialogForm } from "./case-dialog-form";
import { CaseDialogTable } from "./case-dialog-table";
import { Button, CircularProgress, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  addOrUpdateCase,
  updateCaseImage,
} from "src/services/api/api.cases.service";

interface CaseDialogProps {
  openDialog: boolean;
  onCloseDialog: (needRefresh: boolean) => void;
  categories: Category[];
  item?: Case;
}

export function CaseDialog({
  item,
  openDialog,
  onCloseDialog,
  categories,
}: CaseDialogProps): JSX.Element {
  const { t } = useTranslation();
  const [isInSaving, setIsInSaving] = useState(false);
  const [formData, setFormData] = useState<Case>(item || ({} as Case));
  const [imageForm, setImageForm] = useState<FormData>(new FormData());
  const [isModified, setIsModified] = useState<boolean>(false);

  useEffect(() => {
    if (item) {
      setFormData(item);
    } else {
      setFormData({} as Case);
    }
  }, [item]);

  const handleCloseDialog = (needRefresh: boolean) => {
    setFormData({} as Case);
    onCloseDialog(needRefresh);
  };

  const handleSetFormData = (newData: Case) => {
    setFormData({ ...formData, ...newData });
  };
  
  const handleSetIsModified= (isDataModified: boolean) => {
    setIsModified(isDataModified);
  };

  const handleSetImageFormData = (newImageForm: FormData) => {
    setImageForm(newImageForm);
  };

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    setIsInSaving(true);
    try {
      await addOrUpdateCase(formData);
    } catch (error) {
      console.error("Failed to save case:", error);
    }
    if (imageForm.has('image')) {
      updateCaseImage(imageForm);
    }
    setIsInSaving(false);
    handleCloseDialog(true);
  };

  return (
    <Dialog maxWidth="lg" open={openDialog} onClose={() => handleCloseDialog(false)}>
      <form onSubmit={handleSave} noValidate>
      <DialogContent>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid lg={12}>
                <CaseDialogForm
                  categories={categories}
                  onSetFormData={handleSetFormData}
                  onSetImageForm={handleSetImageFormData}
                  formData={formData}
                  onSetIsModified={handleSetIsModified}
                />
              </Grid>
              {item && item?.id ? (
                <>
                  <Grid lg={12}>
                    <Divider />
                  </Grid>
                  <Grid lg={12}>
                    <CaseDialogTable caseId={item.id} />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => handleCloseDialog(false)}>
          {t("COMMON.CANCEL")}
        </Button>
        <Button variant="contained" disabled={isInSaving  || !isModified} type="submit">
          {isInSaving ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <>{t("COMMON.SAVE")}</>
          )}
        </Button>
      </DialogActions>
      </form>
    </Dialog>
  );
}

export default CaseDialog;
