import Grid from "@mui/material/Unstable_Grid2";
import Skeleton from "@mui/material/Skeleton";
import { StatsItem } from "./budget-item";
import { get24HStats } from "src/services/api/api.stats";
import { useEffect, useState } from "react";
import { Stats } from "src/types/stats";
import { useTranslation } from "react-i18next";

export default function TabContent24h(): React.JSX.Element {
    const { t } = useTranslation();
    const [stats, setStats] = useState<Stats>();

    useEffect(() => {
        get24HStats().then((data: Stats | undefined) => setStats(data));
    }, []);

    return (
        <Grid container spacing={1.5}>
            {!stats ? (
                <>
                    <Grid xs={6}>
                        <Skeleton
                            variant="rectangular"
                            height={60}
                            sx={{ borderRadius: 2 }}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <Skeleton
                            variant="rectangular"
                            height={60}
                            sx={{ borderRadius: 2 }}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <Skeleton
                            variant="rectangular"
                            height={60}
                            sx={{ borderRadius: 2 }}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <Skeleton
                            variant="rectangular"
                            height={60}
                            sx={{ borderRadius: 2 }}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <Skeleton
                            variant="rectangular"
                            height={60}
                            sx={{ borderRadius: 2 }}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <Skeleton
                            variant="rectangular"
                            height={60}
                            sx={{ borderRadius: 2 }}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Skeleton
                            variant="rectangular"
                            height={60}
                            sx={{ borderRadius: 2 }}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Skeleton
                            variant="rectangular"
                            height={60}
                            sx={{ borderRadius: 2 }}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Skeleton
                            variant="rectangular"
                            height={60}
                            sx={{ borderRadius: 2 }}
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <Grid container spacing={1.5}>
                        <Grid xs={6}>
                            <StatsItem
                                value={(stats?.gross_deposits ?? 0).toString()}
                                label={t('STATS.REVENUE_GROSS')}
                                color={'primary'}
                                icon={'revenueGross'}
                                showCurrency
                            />
                        </Grid>
                        <Grid xs={6}>
                            <StatsItem
                                value={stats?.net_deposits?.toString()}
                                label={t('STATS.REVENUE_NET')}
                                color={'primary'}
                                icon={'revenueNet'}
                                showCurrency
                            />
                        </Grid>
                        <Grid xs={6}>
                            <StatsItem
                                value={stats?.withdraw?.toString()}
                                label={t('STATS.WITHDRAWS')}
                                color={'error'}
                                icon={'withdraws'}
                                showCurrency
                            />
                        </Grid>
                        <Grid xs={6}>
                            <StatsItem
                                value={stats?.dropped_cost?.toString()}
                                label={t('STATS.DROPPED_COST')}
                                color={'error'}
                                icon={'parachute'}
                                showCurrency
                            />
                        </Grid>
                        <Grid xs={6}>
                            <StatsItem
                                value={stats?.tweaks_withdraw?.toString()}
                                label={t('STATS.TWEAKS_WITHDRAWS')}
                                color={'warning'}
                                icon={'withdraws'}
                                showCurrency
                            />
                        </Grid>
                        <Grid xs={6}>
                            <StatsItem
                                value={`${stats?.rtp}%`}
                                label={t('STATS.AVARAGE_RTP')}
                                color={'warning'}
                                icon={'scales'}
                            />
                        </Grid>
                        <Grid xs={6}>
                            <StatsItem
                                value={(stats?.opening_cost ?? 0).toString()}
                                label={t('STATS.OPENNING_COST')}
                                color={'success'}
                                icon={'invoice'}
                                showCurrency
                            />
                        </Grid>
                        <Grid xs={6}>
                            <StatsItem
                                value={stats?.openings_amount?.toString()}
                                label={t('STATS.OPENINGS_AMOUNT')}
                                color={'success'}
                                icon={'folder-open'}
                            />
                        </Grid>
                        <Grid xs={6}>
                            <StatsItem
                                value={stats?.payers_amount?.toString()}
                                label={t('STATS.PAYERS_AMOUNT')}
                                color={'success'}
                                icon={'payers'}
                            />
                        </Grid>
                        <Grid xs={6}>
                            <StatsItem
                                value={stats?.gross_gaming_revenue?.toString()}
                                label={t('STATS.GROSS_GAMING_REVENUE')}
                                color={'success'}
                                icon={'profit'}
                                showCurrency
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
