

import React, { useEffect, useState } from 'react';
import type { JSX } from 'react';
import { addCaseItem, deleteCaseItem, getCaseItems } from 'src/services/api/api.cases.service';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Unstable_Grid2';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { Trash as TrashIcon } from '@phosphor-icons/react/dist/ssr/Trash';
import { useTranslation } from 'react-i18next';
import type { AggregatedDrop, Skinsback } from 'src/types/cases';
import { DeleteDialog } from 'src/components/common/dialog-delete';
import { StyledTableCell as TableCell } from 'src/components/common/table-cell';
import { SkinsAutocomplete } from './case-dialog-table-search';
import { Typography } from '@mui/material';
import { classImageUrl } from 'src/services/api/api.config';

interface CaseDialogTableProps {
  caseId: number;
}

export function CaseDialogTable({ caseId }: CaseDialogTableProps): JSX.Element {
  const { t } = useTranslation();
  const [selectedSkin, setSelectedSkin] = useState<Skinsback | null>(null);
  const [selectedDrop, setSelectedDrop] = useState<AggregatedDrop>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [drops, setDrop] = useState<AggregatedDrop[]>([]);

  const refresh = async () => {
    getCaseItems(caseId).then((data) => {
      setDrop(data);
    });
  };

  useEffect(() => {
    refresh();
  }, [caseId]);

  const onSelectSkin = async (skin: Skinsback) => {
    setSelectedSkin(skin);
  };

  const onAddItem = async () => {
    const drop = {
      case_id: caseId,
      class_id: selectedSkin?.classid,
      market_hash_name: selectedSkin?.name,
      price_at_receiving: selectedSkin?.price,
      rarity: selectedSkin?.extra?.rarity,
    } as AggregatedDrop;

    await addCaseItem(drop);

    refresh();
  };

  const onDelete = (drop: AggregatedDrop) => {
    setOpenDeleteDialog(true);
    setSelectedDrop(drop);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteItem = async (drop?: AggregatedDrop) => {
    setOpenDeleteDialog(false);
    if (drop) {
      await deleteCaseItem(drop.id);
    }
    refresh();
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ marginTop: '1px' }}>
        <Grid lg={12}>
          <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={3}>
            <Typography variant='h6'>{t('CASES.CONTENT')}</Typography>
            <SkinsAutocomplete selectSkin={onSelectSkin} />
            <div>
              <Button
                startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
                variant="contained"
                disabled={!selectedSkin}
                onClick={onAddItem}
              >
                {t('COMMON.ADD')}
              </Button>
            </div>
          </Stack>
        </Grid>
        <Grid lg={12}>
          <Divider />
        </Grid>
        <Grid lg={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell width={'25%'}>{t('CASES.MARKET_HASH_NAME')}</TableCell>
                <TableCell>{t('CASES.CLASS_ID')}</TableCell>
                <TableCell>{t('CASES.RARITY')}</TableCell>
                <TableCell>{t('CASES.PRICE_AT_RECEIVING')}</TableCell>
                <TableCell>{t('CASES.SKIN_PARTNER')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {drops?.map((drop: AggregatedDrop, index: number) => (
                <TableRow key={index} sx={{ backgroundColor: drop.is_available_in_market_place ? 'initial' : 'var(--mui-palette-error-200)' }}>
                  <TableCell>
                    <img width={40} height={40} src={classImageUrl(drop.class_id)} alt={drop.market_hash_name} />
                  </TableCell>
                  <TableCell>{drop.market_hash_name}</TableCell>
                  <TableCell>{drop.class_id}</TableCell>
                  <TableCell>{drop.rarity}</TableCell>
                  <TableCell>{drop.price_at_receiving}</TableCell>
                  <TableCell>{drop.skin_partner}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        onDelete(drop);
                      }}
                    >
                      <TrashIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        {/*   <Grid container spacing={1}>
                        {formData.aggregated_drop?.map((drop: AggregatedDrop, index: number) => (
                            <Grid lg={2}>
                                <Paper key={index} elevation={3}>
                                    <Typography>{drop.market_hash_name}</Typography>
                                    <img style={{ width: '80%', alignSelf: 'center' }} src={imageUrl('')} />
                                    <Typography>Rarity: {drop.rarity}</Typography>
                                    <Typography>Price: {drop.price_at_receiving}</Typography>
                                    <Typography>Parter: {drop.skin_partner}</Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid> */}
      </Grid>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onDelete={() => handleDeleteItem(selectedDrop)}
        itemName={selectedDrop?.market_hash_name ?? ''}
      />
    </Box>
  );
}
