import { AddCaseResponse, AggregatedDrop, Case, CaseItemsResponse, CaseResponse, OpenCaseResponse, Skinsback, SkinsbackResponse } from "src/types/cases";
import { ApiConfig } from "./api.config";
import { sendRequest } from "../api.services";

export const getCases = async (): Promise<Case[]> => {
    const data = await sendRequest(`${ApiConfig.baseAdminUrl}/admin_get_cases`, 'POST');
    return (data as CaseResponse)?.cases;
}

export const getCaseItems = async (caseId: number): Promise<AggregatedDrop[]> => {
    const data = await sendRequest(`${ApiConfig.baseAdminUrl}/admin_get_case_items`, 'POST', { case_id: caseId });
    return (data as CaseItemsResponse)?.items;
}

export const getSkinsbackItems = async (partialHashName: string): Promise<Skinsback[]> => {
    const data = await sendRequest(`${ApiConfig.baseAdminUrl}/get_skinsback_items?market_hash_name=${encodeURIComponent(partialHashName)}`, 'GET');
    return (data as SkinsbackResponse)?.items;
}

export const addOrUpdateCase = async (caseItem: Case): Promise<AddCaseResponse> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/admin_add_or_edit_case`, 'POST', caseItem);

export const deleteCase = async (caseId: number): Promise<void> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/admin_delete_case`, 'POST', {id: caseId});

export const openCase = async (id: number): Promise<OpenCaseResponse> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/open_case`, 'POST', { case_id: id });

export const resetCaseLogs = async (id: number) =>
    sendRequest(`${ApiConfig.baseAdminUrl}/admin_reset_case_logs`, 'POST', { case_id: id });

export const getCase = async (id: number): Promise<Case | undefined> => {
    const cases = await getCases();
    return cases?.find((c) => c.id === id);
}

export const updateCaseImage = async (formData: FormData): Promise<void> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/admin_upload_case_img`, 'POST', undefined, formData);

export const addCaseItem = async (caseItem: AggregatedDrop): Promise<any> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/admin_add_case_item`, 'POST', caseItem);

export const deleteCaseItem = async (id: number): Promise<void> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/admin_delete_case_item`, 'POST', { id });


