import React, { useState } from "react";
import type { JSX } from "react";
import { AccountInfo } from "src/types/user";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";

import { CustomerTabMain } from "./customer-form-tab-main/customer-form-tab-main";
import { CustomerTabDeposits } from "./customer-form-tab-deposits/customer-form-tab-deposits";

interface CustomerFormTabsProps {
  onSave: (updatedUser: AccountInfo) => void;
  onConfirmDeposit: (orderId: string, amount: number, comment: string) => void;
  user: AccountInfo;
}

export function CustomerFormTabs({ onSave, onConfirmDeposit, user }: CustomerFormTabsProps): JSX.Element {
  const { t } = useTranslation();
  const [value, setValue] = useState("main");

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: string
  ): void => {
    setValue(newValue);
  };

  return (
    <Card
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        height: "100%",
      }}
    >
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          height: "100%",
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t("USER.MAIN")} value="main" />
              <Tab label={t("USER.DEPOSITS_HISTORY")} value="deposits" />
            </TabList>
          </Box>
          <TabPanel
            value="main"
            sx={{
              paddingLeft: 0,
              paddingRight: 0,
              height: "100%",
              paddingBottom: 0,
            }}
          >
            <CustomerTabMain user={user} onSave={onSave}/>
          </TabPanel>
          <TabPanel value="deposits" sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <CustomerTabDeposits user={user} onConfirmDeposit={onConfirmDeposit}/>
          </TabPanel>
        </TabContext>
      </CardContent>
    </Card>
  );
}
