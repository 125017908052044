

import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { PromoCode } from 'src/types/promocode';
import { StyledTableCell as TableCell } from 'src/components/common/table-cell';
import { Button, IconButton, InputAdornment, OutlinedInput, Stack, TableContainer, Typography } from '@mui/material';
import { MagnifyingGlass, Pencil, Plus, Trash } from '@phosphor-icons/react';
import { addPromoCode, deletePromoCode, getPromoCodes, updatePromoCode } from 'src/services/api/api.promocodes.service';
import PromoCodeDialog from './promocode-dialog';
import { DeleteDialog } from 'src/components/common/dialog-delete';

export function PromocodesTable(): React.JSX.Element {

  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<PromoCode>({
    is_reusable: false,
    promo_code: '',
    minimum_deposit_usd: 0,
    minimum_deposit_eur: 0,
    minimum_deposit_rub: 0,
    bonus_percentage: 0,
    activation_limit: 1,
    department: 'SMM',
    region: 'RU',
    active: false,
    notes: '',
  } as PromoCode);
  const [page, setPage] = useState(0);
  const [rowsPerPageState, setRowsPerPageState] = useState(20);
  const [data, setData] = useState<PromoCode[]>([]);
  const [filteredData, setFilteredData] = useState<PromoCode[]>([]);

  const refresh = async () => {
    const data = await getPromoCodes();
    if (data) {
      setData(data);
      setFilteredData(data);
    }
  }

  useEffect(() => {
    refresh();
  }, []);

  const typeToString = (isReusable: boolean) => {
    return isReusable ? t('PROMOCODES.REUSABLE') : t('PROMOCODES.DISPOSABLE');
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPageState(parseInt(event.target.value));
    setPage(0);
  };

  const handleDialogUpdate = async (promoCode?: PromoCode) => {
    if (promoCode) {
      const request = promoCode.id ? updatePromoCode : addPromoCode;
      await request(promoCode);
      await refresh();
    }

    setOpenDialog(false);
  }

  const handleDelete = async () => {
    await deletePromoCode(selectedItem.id);
    await refresh();
    setOpenDeleteDialog(false);
  }

  const visibleRows = useMemo(() => {
    return filteredData.slice(page * rowsPerPageState, page * rowsPerPageState + rowsPerPageState);
  }, [filteredData, page, rowsPerPageState]);

  const filter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    const newData = value ?
      data.filter((item: PromoCode) => item.promo_code.toLowerCase().includes(value)) :
      data;
    setFilteredData(newData);
  }

  const onEdit = (item: PromoCode) => {
    setSelectedItem(item);
    setOpenDialog(true);
  }

  const onDelete = (item: PromoCode) => {
    setSelectedItem(item);
    setOpenDeleteDialog(true);
  }

  const onAdd = () => {
    setSelectedItem({
      is_reusable: false,
      promo_code: '',
      minimum_deposit_usd: 0,
      minimum_deposit_eur: 0,
      minimum_deposit_rub: 0,
      bonus_percentage: 0,
      activation_limit: 1,
      department: 'SMM',
      region: 'RU',
      active: true,
      notes: '',
    } as PromoCode);
    setOpenDialog(true);
  }

  const table = () => {
    return <Box sx={{ overflowX: 'auto' }}>
      <TableContainer>
        <Table sx={{ minWidth: '800px' }}>
          <TableHead>
            <TableRow>
              {/*   <TableCell>{t('COMMON.ID')}</TableCell> */}
              <TableCell>{t('COMMON.PROMOCODE')}</TableCell>
              <TableCell>{t('COMMON.TYPE')}</TableCell>
              <TableCell>{t('PROMOCODES.MIN_DEPOSIT_USD')}</TableCell>
              <TableCell>{t('PROMOCODES.DENOMINATION')}</TableCell>
              <TableCell>{t('PROMOCODES.ACTIVATION_AMOUNT')}</TableCell>
              <TableCell>{t('PROMOCODES.ACTIVATION_LIMIT')}</TableCell>
              <TableCell>{t('PROMOCODES.CAMPAIGN_TYPE')}</TableCell>
              <TableCell>{t('COMMON.CREATION_DATE')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row) => {
              return (
                <TableRow hover key={row.id}>
                  {/*  <TableCell>
                    {row.id}
                  </TableCell> */}
                  <TableCell>
                    {row.promo_code}
                  </TableCell>
                  <TableCell>
                    {typeToString(row.is_reusable)}
                  </TableCell>
                  <TableCell>
                    {row.minimum_deposit_usd}
                  </TableCell>
                  <TableCell>{(row.bonus_percentage * 100).toFixed(1)}</TableCell>
                  <TableCell>{row.activation_count}</TableCell>
                  <TableCell>{row.activation_limit}</TableCell>
                  <TableCell>{row.department}</TableCell>
                  <TableCell>{dayjs(row.created_at).utc(true).local().format('DD.MM.YYYY')}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => onEdit(row)}><Pencil /></IconButton>
                    <IconButton onClick={() => onDelete(row)}><Trash /></IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPageState}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('COMMON.ROWS_PER_PAGE')}
      />
    </Box>
  }

  return (

    <Stack spacing={3}>
      <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={3}>
        <Typography variant="h5">{t('PROMOCODES.HEADER')}</Typography>
        <OutlinedInput
          size={'small'}
          defaultValue=""
          fullWidth
          placeholder={t('PROMOCODES.SEARCH_PLACEHOLDER')}
          startAdornment={
            <InputAdornment position="start">
              <MagnifyingGlass fontSize="var(--icon-fontSize-md)" />
            </InputAdornment>
          }
          sx={{ maxWidth: '500px' }}
          onChange={filter}
        />
        <div>
          <Button startIcon={<Plus fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={onAdd}>
            {t('COMMON.ADD')}
          </Button>
        </div>
      </Stack>
      {table()}
      <PromoCodeDialog item={selectedItem} openDialog={openDialog} onCloseDialog={handleDialogUpdate}></PromoCodeDialog>
      <DeleteDialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} onDelete={handleDelete} itemName={selectedItem.promo_code}></DeleteDialog>
    </Stack>
  );
}
