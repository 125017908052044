import { FullStats, Stats, AdminStats } from "src/types/stats";
import { ApiConfig } from "./api.config";
import { sendRequest } from "../api.services";

export const get24HStats = async (): Promise<Stats> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/statistic/24h`, 'GET');

export const getFullStats = async (): Promise<FullStats> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/statistic/full`, 'GET');

export const getGeneral = async (): Promise<AdminStats> => {
    const data = await sendRequest(`${ApiConfig.baseAdminUrl}/statistic/general`, 'GET');
    return data;
}