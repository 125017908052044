import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { IBonus } from "src/types/bonus";
import { StyledTableCell as TableCell } from "src/components/common/table-cell";
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TableContainer,
  Typography,
} from "@mui/material";
import { MagnifyingGlass, Pencil, Plus, Trash } from "@phosphor-icons/react";
import {
  addBonus,
  deleteBonus,
  getBonuses,
  updateBonus,
} from "src/services/api/api.bonuses.service";
import { BonusDialog } from "./bonus-dialog";
import { DeleteDialog } from "src/components/common/dialog-delete";
import { BonusType, CampaignType } from "src/constants/bonus.constants";

export function BonusesTable(): React.JSX.Element {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IBonus>({
    promo_code: "",
    type: BonusType.CASE,
    bonus_amount_rub: 0,
    bonus_amount_usd: 0,
    bonus_amount_eur: 0,
    min_deposit_rub: 0,
    min_deposit_usd: 0,
    min_deposit_eur: 0,
    active: true,
    new_user_activation_only: false,
    department: CampaignType.SMM,
    activation_limit: 0,
  } as IBonus);
  const [page, setPage] = useState(0);
  const [rowsPerPageState, setRowsPerPageState] = useState(20);
  const [data, setData] = useState<IBonus[]>([]);
  const [filteredData, setFilteredData] = useState<IBonus[]>([]);

  const refresh = async () => {
    const data = await getBonuses();
    if (data) {
      setData(data);
      setFilteredData(data);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageState(parseInt(event.target.value));
    setPage(0);
  };

  const handleDialogUpdate = async (bonus?: IBonus) => {
    if (bonus) {
      const request = bonus.id ? updateBonus : addBonus;
      await request(bonus);
      await refresh();
    }

    setOpenDialog(false);
  };

  const handleDelete = async () => {
    await deleteBonus(selectedItem.id);
    await refresh();
    setOpenDeleteDialog(false);
  };

  const visibleRows = useMemo(() => {
    return filteredData.slice(
      page * rowsPerPageState,
      page * rowsPerPageState + rowsPerPageState
    );
  }, [filteredData, page, rowsPerPageState]);

  const filter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    const newData = value
      ? data.filter((item: IBonus) =>
          item.promo_code.toLowerCase().includes(value)
        )
      : data;
    setFilteredData(newData);
  };

  const onEdit = (item: IBonus) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const onDelete = (item: IBonus) => {
    setSelectedItem(item);
    setOpenDeleteDialog(true);
  };

  const onAdd = () => {
    setSelectedItem({
      promo_code: "",
      type: BonusType.CASE,
      bonus_amount_rub: 0,
      bonus_amount_usd: 0,
      bonus_amount_eur: 0,
      min_deposit_rub: 0,
      min_deposit_usd: 0,
      min_deposit_eur: 0,
      active: true,
      new_user_activation_only: false,
      department: CampaignType.SMM,
      activation_limit: 0,
      active_date: dayjs().toDate(),
    } as IBonus);
    setOpenDialog(true);
  };

  const table = () => {
    return (
      <Box sx={{ overflowX: "auto" }}>
        <TableContainer>
          <Table sx={{ minWidth: "800px" }}>
            <TableHead>
              <TableRow>
                <TableCell>{t("BONUSES.BONUS")}</TableCell>
                <TableCell>{t("BONUSES.BONUS_LINK")}</TableCell>
                <TableCell>{t("BONUSES.BONUS_MIN_DEPOSIT_USD")}</TableCell>
                <TableCell>{t("BONUSES.NEW_USER_BONUS")}</TableCell>
                <TableCell>{t("BONUSES.BONUS_ACTIVATION_LIMIT")}</TableCell>
                <TableCell>{t("BONUSES.BONUS_ACTIVATION_COUNT")}</TableCell>
                <TableCell>
                  {t("BONUSES.BONUS_ACTIVATION_CAMPAIGN_TYPE")}
                </TableCell>
                <TableCell>{t("BONUSES.BONUS_ACTIVE_DATE")}</TableCell>
                <TableCell sx={{width: '8%'}}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.map((row) => {
                return (
                  <TableRow hover key={row.id}>

                    <TableCell>{row.promo_code}</TableCell>
                    <TableCell>{row.case_id}</TableCell>
                    <TableCell>{row.min_deposit_usd}</TableCell>
                    <TableCell>
                      {row.new_user_activation_only
                        ? t("COMMON.YES")
                        : t("COMMON.NO")}
                    </TableCell>
                    <TableCell>{row.activation_limit}</TableCell>
                    <TableCell>{row.activation_count}</TableCell>
                    <TableCell>{row.department}</TableCell>
                    <TableCell>
                      {dayjs(row.active_date).utc(true).local().format("DD.MM.YYYY")}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => onEdit(row)}>
                        <Pencil />
                      </IconButton>
                      <IconButton onClick={() => onDelete(row)}>
                        <Trash />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPageState}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("COMMON.ROWS_PER_PAGE")}
        />
      </Box>
    );
  };

  return (
    <Stack spacing={3}>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={3}
      >
        <Typography variant="h5">{t("BONUSES.HEADER")}</Typography>
        <OutlinedInput
          size={"small"}
          defaultValue=""
          fullWidth
          placeholder={t("BONUSES.BONUS")}
          startAdornment={
            <InputAdornment position="start">
              <MagnifyingGlass fontSize="var(--icon-fontSize-md)" />
            </InputAdornment>
          }
          sx={{ maxWidth: "500px" }}
          onChange={filter}
        />
        <div>
          <Button
            startIcon={<Plus fontSize="var(--icon-fontSize-md)" />}
            variant="contained"
            onClick={onAdd}
          >
            {t("COMMON.ADD")}
          </Button>
        </div>
      </Stack>
      {table()}
      <BonusDialog
        item={selectedItem}
        openDialog={openDialog}
        onCloseDialog={handleDialogUpdate}
      ></BonusDialog>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={handleDelete}
        itemName={selectedItem.promo_code}
      ></DeleteDialog>
    </Stack>
  );
}
