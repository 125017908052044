import React from 'react';
import type { JSX } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';

interface DeleteDialogProps {
  open: boolean;
  itemName: string;
  onDelete: () => void;
  onClose?: () => void;
  customText?: string;
}

export function DeleteDialog({ open, onClose, onDelete, itemName, customText }: DeleteDialogProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('COMMON.DELETE')} {itemName}?</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          {customText || t('COMMON.DELETE_WARNING', {itemName})}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
        {t('COMMON.CANCEL')}
        </Button>
        <Button onClick={onDelete} color="error" variant="contained">
        {t('COMMON.DELETE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
