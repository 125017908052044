import React, { useEffect, useMemo, useState } from "react";
import type { JSX } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { INews } from "src/types/news";
import { DestinationCategory } from "src/constants/news.constants";
import Grid from "@mui/material/Unstable_Grid2";
import { sendNotification } from "src/services/api/api.news.service";
import { getUsers } from "src/services/api/api.user.service";

interface SendNewsDialogProps {
  item: INews;
  openDialog: boolean;
  onCloseDialog: (needRefresh: boolean) => void;
}

export function SendNotificationDialog({
  item,
  openDialog,
  onCloseDialog,
}: SendNewsDialogProps): JSX.Element {
  const { t } = useTranslation();
  const [news, setNews] = useState<INews>(item);
  const [specificCategory, setDestinationCategory] = useState<string>(DestinationCategory.None);
  const [specificUsers, setSpecificUsers] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]);
  const [sendToAll, setSendToAll] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string>('');

  const resetAndClose = (needRefresh: boolean) => {
    setSpecificUsers([]);
    setDestinationCategory(DestinationCategory.None);
    setSendToAll(false);
    onCloseDialog(needRefresh);
  }

  useEffect(() => {
    if (openDialog) {
      setNews(item);
      getUsers().then(users => { setUsers(users) })
    }
  }, [item, openDialog]);

  const send = async () => {
    setIsLoading(true);

    await sendNotification({
      notification_id: news.id,
      specific_category: specificCategory,
      specific_users: specificUsers,
      available_for_everyone: sendToAll,
    });

    setIsLoading(false);
    resetAndClose(true);
  }

  return (
    <Dialog open={openDialog} onClose={() => resetAndClose(false)} fullWidth>
      <DialogTitle>{t("NOTIFICATIONS.SEND_TO")}</DialogTitle>
      <DialogContent>
        <Card sx={{ marginTop: 0.25 }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid lg={12} md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendToAll}
                      onChange={() => setSendToAll(!sendToAll)}
                    />
                  }
                  label={t("NOTIFICATIONS.AVAILABLE_FOR_EVERYONE")}
                />
              </Grid>
              <Grid lg={12} md={12} xs={12}>
                <FormControl size="small" fullWidth margin="dense" disabled={sendToAll}>
                  <InputLabel> {t("NOTIFICATIONS.SPECIFIC_CATEGORY")}</InputLabel>
                  <Select
                    label={t("NOTIFICATIONS.SPECIFIC_CATEGORY")}
                    onChange={(e: SelectChangeEvent) => setDestinationCategory(e.target.value)}
                    name="type"
                    value={specificCategory}
                  >
                    <MenuItem value={DestinationCategory.None}>
                      {t(`NOTIFICATIONS.${DestinationCategory.None.toUpperCase()}`)}
                    </MenuItem>
                    <MenuItem value={DestinationCategory.ALL_CURRENT_USERS}>
                    {t(`NOTIFICATIONS.${DestinationCategory.ALL_CURRENT_USERS.toUpperCase()}`)}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid lg={12} md={12} xs={12}>
                <FormControl fullWidth margin="dense" size="small" disabled={sendToAll}>
                  <Autocomplete
                    value={users.filter(user => specificUsers.includes(user.id))}
                    onChange={(event, newValue) => {
                      setSpecificUsers(newValue.map(user => user.id));
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    disabled={sendToAll}
                    multiple
                    options={inputValue.length >= 3 ? users : []}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const tagProps = getTagProps({ index });
                        return (
                          <Chip
                            variant="outlined"
                            label={option.steam_account_info.persona_name || ''}
                            {...tagProps}
                          />
                        );
                      })
                    }
                    getOptionLabel={(option) => option.steam_account_info.persona_name || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={t("NOTIFICATIONS.SPECIFIC_USERS")}
                        placeholder={t("NOTIFICATIONS.SPECIFIC_USERS_PLACEHOLDER")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => resetAndClose(false)}>
          {t("COMMON.CANCEL")}
        </Button>
        <Button variant="contained" disabled={isLoading} onClick={() => send()}>
          {isLoading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            <>{t("NOTIFICATIONS.SEND")}</>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
