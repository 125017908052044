import React, { ChangeEvent, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Unstable_Grid2";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { Box, FormControlLabel, TextField } from "@mui/material";
import { AccountInfo } from "src/types/user";
import { UserType, UserModerationStatus } from "src/constants/user.constants";

interface CustomerTabMainProps {
  onSave: (updatedUser: AccountInfo) => void;
  user: AccountInfo;
}

export function CustomerTabMain({
  onSave,
  user,
}: CustomerTabMainProps): JSX.Element {
  const { t } = useTranslation();
  const [isWithdrawOpened, setIsWithdrawOpened] = useState<boolean>(false);
  const [type, setType] = useState<UserType>(UserType.REGULAR);
  const [moderationStatus, setModerationStatus] =
    useState<UserModerationStatus>(UserModerationStatus.NONE);
  const [userNotes, setUserNotes] = useState<string>("");
  const [userModerationNotes, setUserModerationNotes] = useState<string>("");

  useEffect(() => {
    if (user) {
      setIsWithdrawOpened(!!user.withdraw_open);
      setType((user.type as UserType) ?? UserType.REGULAR);
      setModerationStatus(
        (user.moderation as UserModerationStatus) ?? UserModerationStatus.NONE
      );
      setUserNotes(user.notes || "");
      setUserModerationNotes(user.moderation_notes || "");
    }
  }, [user]);

  const handleWithdrawChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsWithdrawOpened(event.target.checked);
  };

  const handleTypeChange = (event: SelectChangeEvent<UserType>) => {
    setType(event.target.value as UserType);
  };

  const handleModerationStatusChange = (
    event: SelectChangeEvent<UserModerationStatus>
  ) => {
    setModerationStatus(event.target.value as UserModerationStatus);
  };

  const handleUserNotesChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUserNotes(event.target.value);
  };

  const handleUserModerationNotesChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUserModerationNotes(event.target.value);
  };

  const getSelectColor = (status: UserModerationStatus) => {
    switch (status) {
      case UserModerationStatus.PENDING:
        return "info.light";
      case UserModerationStatus.SHADOW:
        return "warning.light";
      case UserModerationStatus.PASSED:
        return "success.light";
      case UserModerationStatus.REJECTED:
        return "error.light";
      default:
        return "primary.light";
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const updatedUser: AccountInfo = {
      ...user,
      withdraw_open: isWithdrawOpened,
      type,
      moderation: moderationStatus,
      notes: userNotes,
      moderation_notes: userModerationNotes,
      steam_account_info: user?.steam_account_info ?? undefined,
    };
    onSave(updatedUser);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: "100%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={3}>
        <Grid lg={6} xs={4}>
          <FormControl fullWidth>
            <InputLabel>{t("COMMON.TYPE")}</InputLabel>
            <Select
              id="type-select"
              value={type}
              label={t("COMMON.TYPE")}
              onChange={handleTypeChange}
              size="small"
            >
              <MenuItem value={UserType.REGULAR}>
                {t("USER.TYPE_NORMAL")}
              </MenuItem>
              <MenuItem value={UserType.BANNED}>
                {t("USER.TYPE_BANNED")}
              </MenuItem>
              <MenuItem value={UserType.MULTI}>{t("USER.TYPE_MULTI")}</MenuItem>
              <MenuItem value={UserType.TWEAK}>{t("USER.TYPE_RTP")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid lg={6} xs={4}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isWithdrawOpened}
                  onChange={handleWithdrawChange}
                />
              }
              label={t("USER.WITHDRAWS_OPENED")}
            />
          </FormControl>
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <FormControl sx={{ width: '100%' }}>
            <label htmlFor="user-notes" style={{ marginBottom: '8px', display: 'block' }}>
              {t("USER.NOTES")}:
            </label>
            <textarea
              id="user-notes"
              rows={3}
              value={userNotes}
              onChange={handleUserNotesChange}
              style={{
                width: '100%',
                padding: '8px',
                borderColor: '#c4c4c4',
                borderRadius: '10px',
                overflow: 'auto',
                resize: 'vertical',
                fontSize: '14px',
                fontFamily: 'inherit',
              }}
            />
          </FormControl>
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <Divider sx={{ width: "100%" }} />
        </Grid>
        <Grid lg={12} md={12} xs={12} container spacing={1}>
          <Grid lg={12} md={12} xs={12}>
            <FormControl fullWidth>
              <Select
                id="moderation-status-select"
                value={moderationStatus}
                label={t("USER.MODERATION_STATUS")}
                onChange={handleModerationStatusChange}
                size="small"
                sx={{
                  bgcolor: getSelectColor(moderationStatus),
                  color: "black",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                <MenuItem value={UserModerationStatus.NONE}>
                  {t("USER.NO_MODERATION")}
                </MenuItem>
                <MenuItem value={UserModerationStatus.PENDING}>
                  {t("USER.MODERATION_IN_PROGRESS")}
                </MenuItem>
                <MenuItem value={UserModerationStatus.SHADOW}>
                  {t("USER.SHADOW_MODERATION")}
                </MenuItem>
                <MenuItem value={UserModerationStatus.PASSED}>
                  {t("USER.MODERATION_PASSED")}
                </MenuItem>
                <MenuItem value={UserModerationStatus.REJECTED}>
                  {t("USER.MODERATION_FAILED")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid lg={12} md={12} xs={12}>
            <FormControl sx={{ width: '100%' }}>
              <label htmlFor="user-reason" style={{ marginBottom: '8px', display: 'block' }}>
                {t("USER.REASON")}:
              </label>
              <textarea
                id="user-reason"
                value={userModerationNotes}
                onChange={handleUserModerationNotesChange}
                rows={3}
                style={{
                  width: '100%',
                  padding: '8px',
                  borderColor: '#c4c4c4',
                  borderRadius: '10px',
                  overflow: 'auto',
                  resize: 'vertical',
                  fontSize: '14px',
                  fontFamily: 'inherit',
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: "flex", justifyContent: "flex-end", padding: 2 }}>
        <Button variant="contained" type="submit">
          {t("COMMON.SAVE")}
        </Button>
      </Box>
    </form>
  );
}
