export interface LoginData {
    login: string;
    password: string;
}

export enum AdminRole {
    Regular = 'regular',
    SuperAdmin = 'superadmin'
}

export interface AdminUser {
    id: number;
    steam_id: string;
    email: string;
    active: boolean;
    role: AdminRole;
}