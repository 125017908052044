import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import Skeleton from "@mui/material/Skeleton";
import { CustomerGeneral } from "src/components/user/customer-general/customer-general";
import { CustomerFormTabs } from "src/components/user/customer-form-tabs/customer-form-tabs";
import {
  confirmDeposit,
  getUser,
  updateUser,
} from "src/services/api/api.user.service";
import { AccountInfo } from "src/types/user";
import { useParams } from "react-router-dom";

export default function UserPage(): React.JSX.Element {
  const { id } = useParams();
  const [user, setUser] = useState<AccountInfo>();
  const [isLoading, setIsLoading] = useState(true);
  const loadUser = async (userId: number) => {
    const data = await getUser(userId);
    setUser(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (id) {
      loadUser(parseInt(id));
    }
  }, [id]);

  const handleSave = async (updatedUser: AccountInfo) => {
    await updateUser(updatedUser);
  };

  const onConfirmDeposit = async (orderId: string, amount: number, comment: string) => {
    if (user) {
      await confirmDeposit(orderId, amount, comment)
      await loadUser(user.id);
    }
  };

  return (
    <Stack style={{ height: "100%" }}>
      <Grid container spacing={1}>
        <Grid lg={12}>
          {isLoading ? (
            <Box>
              <Skeleton variant="rectangular" width="100%" height={40} />
              <Skeleton variant="text" width="80%" sx={{ mt: 2 }} />
              <Skeleton variant="text" width="60%" />
            </Box>
          ) : (
            <CustomerGeneral user={user} />
          )}
        </Grid>
        <Grid lg={12}>
          {isLoading ? (
            <>
              <Skeleton variant="rectangular" width="100%" height={40} />
              <Skeleton variant="text" width="90%" sx={{ mt: 2 }} />
              <Skeleton variant="text" width="70%" />
              <Skeleton variant="text" width="100%" sx={{ mt: 4 }} />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={60}
                sx={{ mt: 2 }}
              />
            </>
          ) : (
            user && (
              <CustomerFormTabs
                user={user}
                onSave={handleSave}
                onConfirmDeposit={onConfirmDeposit}
              />
            )
          )}
        </Grid>
      </Grid>
    </Stack>
  );
}
