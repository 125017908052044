import { getHeaders } from "./api/api.config";

export type HttpMethods = "GET" | "POST" | "PUT" | "DELETE";

export const sendRequest = async (url: string, method: HttpMethods, body?: any, formData?: FormData): Promise<any> => {
    try {
        const bodyContent = formData ?? (body ? JSON.stringify(body) : undefined);
        const response = await fetch(url, {
            method,
            headers: getHeaders(),
            body: bodyContent
        });

        if (response.status == 401) {
            window.location.href = '/login';
            await new Promise(resolve => setTimeout(resolve, 100));
        }

        const content = await response.json();
        return content;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return;
}