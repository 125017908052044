import { PromoCode } from "src/types/promocode";
import { ApiConfig } from "./api.config";
import { sendRequest } from "../api.services";

export const getPromoCodes = async (): Promise<PromoCode[]> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/promo_codes`, 'GET');

export const addPromoCode = async (promocode: PromoCode): Promise<PromoCode> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/promo_codes`, 'POST', promocode)

export const updatePromoCode = async (promocode: PromoCode): Promise<PromoCode | undefined> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/promo_codes/${promocode.id}`, 'PUT', promocode)

export const deletePromoCode = async (id: number): Promise<void> =>
    sendRequest(`${ApiConfig.baseAdminUrl}/promo_codes/${id}`, 'DELETE');