import React, { useEffect, useState } from "react";
import type { JSX } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { PromoCode } from "src/types/promocode";
import Grid from "@mui/material/Unstable_Grid2";

interface PromoCodeProps {
  item: PromoCode;
  openDialog: boolean;
  onCloseDialog: (promoCode?: PromoCode) => void;
}

const departements = ["SMM", "SUPPORT", "PRODUCT", "AFFILIATE"];

const regions = ["RU", "WORLD", "EU"];

export function PromoCodeDialog({
  item,
  openDialog,
  onCloseDialog,
}: PromoCodeProps): JSX.Element {
  const { t } = useTranslation();
  const [promoCode, setPromoCode] = useState<PromoCode>(item);
  const [percentageValue, setPercentageValue] = useState(0);

  useEffect(() => {
    setPromoCode(item);
    setPercentageValue(0);
  }, [item]);

  const onChange = (name: string, value: any) => {
    setPromoCode({ ...promoCode, [name]: value });
  };

  const onSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const onCheckboxChange = (event: React.SyntheticEvent, checked: boolean) => {
    const { name } = event.target as HTMLInputElement;
    onChange(name, checked);
  };

  useEffect(() => {
    if (promoCode?.bonus_percentage) {
      setPercentageValue(
        parseFloat((promoCode.bonus_percentage * 100).toFixed(2))
      );
    }
  }, [promoCode?.bonus_percentage]);

  const handlePercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === "") {
      setPercentageValue(0);
      setPromoCode({ ...promoCode, bonus_percentage: 0 });
      return;
    }

    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= 40) {
      setPercentageValue(parsedValue);
      setPromoCode({
        ...promoCode,
        bonus_percentage: parseFloat((parsedValue / 100).toFixed(4)),
      });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    onCloseDialog(promoCode);
  };

  const handleInvalid = (e: any) => {
    e.target.setCustomValidity(t("COMMON.FORM_VALIDATION_MESSAGE"));
  };
  const handleInput = (e: any) => {
    e.target.setCustomValidity("");
  };

  return (
    <Dialog maxWidth="lg" open={openDialog} onClose={() => onCloseDialog()}>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid lg={6}>
                  <TextField
                    label={t("COMMON.PROMOCODE")}
                    value={promoCode?.promo_code}
                    name="promo_code"
                    onChange={(e) =>
                      setPromoCode({
                        ...promoCode,
                        promo_code: e.target.value.toUpperCase(),
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                    }}
                  />
                </Grid>
                <Grid
                  lg={3}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={promoCode?.is_reusable}
                        onChange={onCheckboxChange}
                        name="is_reusable"
                      />
                    }
                    label={t("PROMOCODES.REUSABLE")}
                  />
                </Grid>
                <Grid
                  lg={3}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={promoCode?.active}
                        onChange={onCheckboxChange}
                        name="active"
                      />
                    }
                    label={t("PROMOCODES.ACTIVE")}
                  />
                </Grid>
                <Grid lg={6}>
                  <FormControl size="small" fullWidth margin="dense">
                    <InputLabel> {t("PROMOCODES.REGION")}</InputLabel>
                    <Select
                      label={t("PROMOCODES.REGION")}
                      name="region"
                      value={promoCode?.region}
                      onChange={onSelectChange}
                    >
                      {regions?.map((region) => (
                        <MenuItem key={region} value={region}>
                          {region}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid lg={6}>
                  <FormControl size="small" fullWidth margin="dense">
                    <InputLabel> {t("PROMOCODES.CAMPAIGN_TYPE")}</InputLabel>
                    <Select
                      label={t("PROMOCODES.CAMPAIGN_TYPE")}
                      onChange={onSelectChange}
                      name="department"
                      value={promoCode?.department}
                    >
                      {departements?.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid lg={4}>
                  <TextField
                    type="number"
                    label={t("PROMOCODES.MIN_DEPOSIT_USD")}
                    value={promoCode?.minimum_deposit_usd}
                    onChange={(e) =>
                      setPromoCode({
                        ...promoCode,
                        minimum_deposit_usd: parseFloat(e.target.value),
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                      min: 0,
                      step: 0.1,
                    }}
                  />
                </Grid>
                <Grid lg={4}>
                  <TextField
                    type="number"
                    label={t("PROMOCODES.MIN_DEPOSIT_EUR")}
                    value={promoCode?.minimum_deposit_eur}
                    onChange={(e) =>
                      setPromoCode({
                        ...promoCode,
                        minimum_deposit_eur: parseFloat(e.target.value),
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                      min: 0,
                      step: 0.1,
                    }}
                  />
                </Grid>
                <Grid lg={4}>
                  <TextField
                    type="number"
                    inputProps={{ min: 0 }}
                    label={t("PROMOCODES.MIN_DEPOSIT_RUB")}
                    value={promoCode?.minimum_deposit_rub}
                    onChange={(e) =>
                      setPromoCode({
                        ...promoCode,
                        minimum_deposit_rub: parseInt(e.target.value),
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                  />
                </Grid>
                <Grid lg={6}>
                  <TextField
                    type="number"
                    label={t("PROMOCODES.DENOMINATION")}
                    value={percentageValue || ""}
                    onChange={handlePercentageChange}
                    placeholder="0"
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                    inputProps={{
                      min: 1,
                      max: 40,
                      step: 0.5,
                      onInvalid: (e: any) => {
                        e.target.setCustomValidity(
                          t("PROMOCODES.FORM_PERCENT_VALIDATION_MESSAGE")
                        );
                      },
                      onInput: handleInput,
                    }}
                  />
                </Grid>
                <Grid lg={6}>
                  <TextField
                    type="number"
                    label={t("PROMOCODES.ACTIVATION_LIMIT")}
                    value={promoCode?.activation_limit || ""}
                    placeholder="0"
                    onChange={(e) =>
                      setPromoCode({
                        ...promoCode,
                        activation_limit: parseInt(e.target.value),
                      })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    required
                    inputProps={{
                      onInvalid: handleInvalid,
                      onInput: handleInput,
                      min: 0,
                    }}
                  />
                </Grid>
                <Grid lg={12}>
                  <TextField
                    label={t("PROMOCODES.NOTES")}
                    value={promoCode?.notes}
                    onChange={(e) =>
                      setPromoCode({ ...promoCode, notes: e.target.value })
                    }
                    fullWidth
                    margin="dense"
                    size="small"
                    multiline
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => onCloseDialog()}>
            {t("COMMON.CANCEL")}
          </Button>
          <Button variant="contained" type="submit">
            {t("COMMON.SAVE")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default PromoCodeDialog;
