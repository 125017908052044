import { config } from "src/config";
import { authClient } from "../auth.service";

export const ApiConfig = {
    baseUrl: config.BASE_URL,
    baseAdminUrl: `${config.BASE_URL}/admin-new/api`,
    token: () => authClient.getToken()
}

export const getHeaders = (additionalHeaders: Record<string, string> = {}): HeadersInit => {
    return {
        'Accept': 'application/json, text/plain, */*',
        'Authorization': ApiConfig.token() ?? '',
        ...additionalHeaders
    };
};

export const imageUrl = (path: string) => {
    return path ? `${ApiConfig.baseUrl}${path}` : '/assets/no-image.png';
};

export const classImageUrl = (classId: string) => `https://steamcommunity-a.akamaihd.net/economy/image/class/730/${classId}/50fx50f`;

export const loginUrl = () => config.BASE_URL;