import React, { useState } from "react";
import { TextField, Button, Stack, Alert } from "@mui/material";
import { authClient } from "src/services/auth.service";
import { useNavigate } from "react-router-dom";

export default function LoginPage(): React.JSX.Element {
  const [emailOrSteamId, setEmailOrSteamId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);

    try {
     const response = await authClient.signIn(emailOrSteamId, password);
      if (response.error) {
        setError(response.error);
      } else {
        navigate('/stats');
      } 
    } catch (err) {
      setError("Произошла ошибка. Пожалуйста, попробуйте снова.");
    } finally {
      setIsPending(false);
    }
  };

  return (
    <Stack
      spacing={4}
      sx={{
        width: 300,
        margin: "0 auto",
        padding: 4,
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleLogin}>
        <Stack spacing={2}>
          <TextField
            label="Login"
            type="text"
            value={emailOrSteamId}
            onChange={(e) => setEmailOrSteamId(e.target.value)}
            required
            fullWidth
            size="small"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
            size='small'
            InputLabelProps={{ shrink: true }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isPending}
          >
            {isPending ? "Вход..." : "Войти"}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}
