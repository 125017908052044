export enum UserType {
  REGULAR = 'regular',
  MULTI = 'multi',
  BANNED = 'banned',
  TWEAK = 'tweak',
}

export enum UserModerationStatus {
  NONE = 'none',
  PENDING = 'pending',
  SHADOW = 'shadow',
  PASSED = 'passed',
  REJECTED = 'rejected',
}