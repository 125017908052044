

import * as React from 'react';
import { usePopover } from 'src/hooks/use-popover';
import { UserPopover } from './user-popover';
import { Box, Typography } from '@mui/material';
import { useUser } from 'src/hooks/use-user';
import { AdminUser } from 'src/types/admin';

export function UserProfile(): React.JSX.Element {
  const userPopover = usePopover<HTMLDivElement>();
  const { user } = useUser() as { user: AdminUser | null };

  return (
    <React.Fragment>
      <Box ref={userPopover.anchorRef} display={'flex'} justifyContent={'center'} alignItems={'center'}
        onClick={userPopover.handleOpen} sx={{ padding: '20px 0', ':hover': { cursor: 'pointer' } }}>
        <Typography>{user?.email}</Typography>
      </Box>
      <UserPopover anchorEl={userPopover.anchorRef.current} onClose={userPopover.handleClose} open={userPopover.open} />
    </React.Fragment >
  );
}
