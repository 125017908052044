

import * as React from 'react';
import { authClient } from 'src/services/auth.service';
import { useNavigate } from 'react-router-dom';
import { AdminUser } from 'src/types/admin';

export interface UserContextValue {
  user?: AdminUser;
  isLoading: boolean;
  checkSession?: () => Promise<void>;
}

export const UserContext = React.createContext<UserContextValue | undefined>(undefined);

export interface UserProviderProps {
  children: React.ReactNode;
}

export function UserProvider({ children }: UserProviderProps): React.JSX.Element {
  const navigate = useNavigate();
  const [state, setState] = React.useState<{ user?: AdminUser; error?: string; isLoading: boolean}>({
    user: undefined,
    error: undefined,
    isLoading: true,
  });

  const checkSession = React.useCallback(async (): Promise<void> => {
    try {
      const { data, error } = await authClient.getUserProfile();

      if (error) {
        navigate('/login');
      }

      setState((prev) => ({ ...prev, user: data as AdminUser, isLoading: false }));

    } catch (err) {
      console.error(err);
      setState((prev) => ({ ...prev, user: undefined, isLoading: false }));
    }
  }, []);

  React.useEffect(() => {
    checkSession().catch((err: unknown) => {
      console.error(err);
    });
  }, []);

  return <UserContext.Provider value={{ ...state, checkSession }}>{children}</UserContext.Provider>;
}

export const UserConsumer = UserContext.Consumer;
