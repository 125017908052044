import { useMemo } from 'react';
import { Totals } from 'src/types/stats';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export interface TotalsProps {
    totals?: Totals;
}

export default function TotalsComponent({ totals }: TotalsProps): React.JSX.Element {
    const { t } = useTranslation();
    const currency = 'RUB';

    const statsArray = useMemo(() => [
        { label: 'STATS.REVENUE_GROSS', value: totals?.gross_deposits, suffix: currency },
        { label: 'STATS.REVENUE_NET', value: totals?.net_deposits, suffix: currency },
        { label: 'STATS.OPENNING_COST', value: totals?.opening_cost, suffix: currency },
        { label: 'STATS.DROPPED_COST', value: totals?.dropped_cost, suffix: currency },
        { label: 'STATS.AVARAGE_RTP', value: `${totals?.rtp}%`, suffix: '' },
        { label: 'STATS.WITHDRAWS', value: `${totals?.withdraw}`, suffix: '' },
        { label: 'STATS.TWEAKS_WITHDRAWS', value: `${totals?.tweaks_withdraw}`, suffix: '' },
        { label: 'STATS.USERS_BALLANCE', value: totals?.users_balance, suffix: currency },
        { label: 'STATS.GROSS_GAMING_REVENUE', value: totals?.gross_gaming_revenue, suffix: currency },
        { label: 'STATS.NET_GAMING_REVENUE', value: totals?.net_gaming_revenue, suffix: currency },
    ], [totals]);

    return (
        <Grid container>
            {statsArray.map((stat, index) => (
                <Grid key={index} lg={6}>
                    <Typography>
                        {t(stat.label)}: <strong>{stat.value} {stat.suffix}</strong>
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
}