import React, { useRef } from "react";
import type { JSX } from "react";
import { Box, type SelectChangeEvent } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";

import type { Case, Category } from "src/types/cases";

import { CaseDialogFormImage } from "./case-dialog-form-image";

interface CaseDialogFormProps {
  formData: Case;
  onSetFormData: (value: Case) => void;
  onSetImageForm: (value: FormData) => void;
  onSetIsModified: (value: boolean) => void;
  categories: Category[];
}

export function CaseDialogForm({
  formData,
  onSetFormData,
  onSetImageForm,
  onSetIsModified,
  categories,
}: CaseDialogFormProps): JSX.Element {
  const { t } = useTranslation();
  const selectRef = useRef<HTMLDivElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onSetFormData({
      ...formData,
      [name]:
        name === "price_of_opening" || name === "order_in_market"
          ? parseFloat(value)
          : value,
    });
    onSetIsModified(true);
  };

  const handleChangeRTPWithValidation = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    const formattedValue = value.replace(",", ".");
    let floatValue = parseFloat(formattedValue);

    if (name === "rtp") {
      if (floatValue > 1) {
        floatValue = 1;
      } else if (floatValue < 0) {
        floatValue = 0;
      }
    }

    onSetFormData({
      ...formData,
      [name]: floatValue,
    });
    onSetIsModified(true);
  };
  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    onSetFormData({
      ...formData,
      [name]: value,
    });
    onSetIsModified(true);
    if (selectRef.current) {
      const input = selectRef.current.querySelector('input');
      if (input) {
        input.setCustomValidity("");
      }
    }
  };

  const handleCheckboxChange = (
    event: React.SyntheticEvent,
    checked: boolean
  ) => {
    const { name } = event.target as HTMLInputElement;
    onSetFormData({
      ...formData,
      [name]: checked,
    });
    onSetIsModified(true);
  };

  const onImageUploaded = (imageForm: FormData) => {
    onSetImageForm(imageForm);
    onSetIsModified(true);
  };

  const handleInvalid = (e: any) => {
    e.target.setCustomValidity(t("COMMON.FORM_VALIDATION_MESSAGE"));
  };
  const handleInvalidSelect = (e: React.InvalidEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (selectRef.current) {
      const input = selectRef.current.querySelector('input');
      if (input) {
        input.setCustomValidity(t("COMMON.FORM_VALIDATION_MESSAGE"));
      }
    }
  };
  const handleInput = (e: any) => {
    e.target.setCustomValidity("");
  };

  return (
    <Stack>
      <Grid container spacing={2}>
        {formData && formData?.id ? (
          <Grid lg={3} md={2} xs={12}>
            <CaseDialogFormImage
              caseId={formData.id}
              imagePath={formData.img_path}
              onImageUploaded={onImageUploaded}
            />
          </Grid>
        ) : null}
        <Grid
          container
          spacing={1}
          lg={formData && formData?.id ? 9 : 12}
          md={10}
          xs={12}
        >
          <Grid container spacing={1} lg={12} md={12} xs={12}>
            <Grid lg={4} md={6} xs={12}>
              <FormControl fullWidth size="small" required>
                <InputLabel> {t("CASES.CATEGORY")}</InputLabel>
                <Select
                  label={t("CASES.CATEGORY")}
                  name="category"
                  value={formData?.category ?? ""}
                  onChange={handleSelectChange}
                  ref={selectRef}
                  onInvalid={handleInvalidSelect}
                  inputProps={{
                    autoComplete: "off",
                  }}
                >
                  {categories.map((category, index) => (
                    <MenuItem key={index} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid lg={8} md={6} xs={12}>
              <TextField
                fullWidth
                label={t("COMMON.TITLE")}
                name="name"
                value={formData?.name ?? ""}
                onChange={handleChange}
                size="small"
                required
                autoComplete="off"
                inputProps={{
                  onInvalid: handleInvalid,
                  onInput: handleInput,
                }}
              />
            </Grid>
            <Grid lg={4} md={6} xs={12}>
              <TextField
                fullWidth
                label={t("CASES.OPENING_PRICE")}
                name="price_of_opening"
                type="number"
                value={formData?.price_of_opening ?? 0}
                onChange={handleChange}
                size="small"
                required
                inputProps={{
                  onInvalid: handleInvalid,
                  onInput: handleInput,
                }}
              />
            </Grid>
            <Grid lg={4} md={6} xs={12}>
              <TextField
                fullWidth
                label={t("CASES.RTP")}
                name="rtp"
                type="number"
                value={formData?.rtp ?? 0}
                onChange={handleChangeRTPWithValidation}
                size="small"
                required
                inputProps={{
                  onInvalid: handleInvalid,
                  onInput: handleInput,
                  min: 0,
                  max: 1,
                  step: 0.01,
                }}
              />
            </Grid>
            <Grid lg={4} md={6} xs={12}>
              <TextField
                fullWidth
                label={t("COMMON.MARKET_ORDER")}
                name="order_in_market"
                type="number"
                value={formData?.order_in_market ?? 0}
                onChange={handleChange}
                size="small"
                required
                inputProps={{
                  onInvalid: handleInvalid,
                  onInput: handleInput,
                }}
              />
            </Grid>
            <Grid lg={4} md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData?.is_ready_for_publish ?? false}
                    onChange={handleCheckboxChange}
                    name="is_ready_for_publish"
                  />
                }
                label={t("CASES.PUBLISH")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 0,
          gap: 1,
        }}
      ></Box>
    </Stack>
  );
}
