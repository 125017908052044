import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Stack,
  Box,
  Button,
} from "@mui/material";
import { Pencil, Plus, Trash } from "@phosphor-icons/react";
import { Category } from "src/types/cases";
import CategoryDialog from "./categories-dialog";
import { useTranslation } from "react-i18next";
import { StyledTableCell as TableCell } from "src/components/common/table-cell";
import {
  addOrUpdateCategory,
  createCategory,
  deteleteCategory,
  getCategories,
} from "src/services/api/api.categories.service";
import { DeleteDialog } from "src/components/common/dialog-delete";

const Categories: React.FC = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category>(
    {} as Category
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  const refresh = async () => {
    setLoading(true);
    const data = await getCategories();
    setCategories(data?.sort((a, b) => a.id - b.id));
    setLoading(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleEdit = (category: Category) => {
    setSelectedCategory(category);
    setOpenDialog(true);
  };

  const handleAddCategory = () => {
    setSelectedCategory({} as Category);
    setOpenDialog(true);
  };

  const handleDelete = (category: Category) => {
    setSelectedCategory(category);
    setOpenDeleteDialog(true);
  };

  const handleDeleteCategory = async () => {
    await deteleteCategory(selectedCategory.id);
    await refresh();
    setOpenDeleteDialog(false);
  };

  const handleSaveCategory = async (category?: Category) => {
    if (category) {
      const saveFunc = category.id ? addOrUpdateCategory : createCategory;
      await saveFunc(category);
      await refresh();
    }

    setOpenDialog(false);
  };

  return (
    <div>
      <Stack spacing={1}>
        <Box display={"flex"} justifyContent={"end"}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Plus />}
            onClick={() => handleAddCategory()}
          >
            {t("COMMON.ADD")}
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {/*   <TableCell sx={{ width: '20%' }}>{t('COMMON.ID')}</TableCell> */}
                <TableCell sx={{ width: "40%" }}>{t("COMMON.TITLE")}</TableCell>
                <TableCell sx={{ width: "20%" }}>
                  {t("COMMON.MARKET_ORDER")}
                </TableCell>
                <TableCell sx={{ width: "20%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array.from(new Array(5)).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton variant="text" width="80%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width="60%" />
                    </TableCell>
                    <TableCell>
                      <Stack direction={"row"} justifyContent={"end"}>
                        <Skeleton variant="circular" width={24} height={24} />
                        <Skeleton
                          variant="circular"
                          width={24}
                          height={24}
                          style={{ marginLeft: 8 }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
                : categories.map((category) => (
                  <TableRow key={category.id}>
                    {/*  <TableCell>{category.id}</TableCell> */}
                    <TableCell>{category.name}</TableCell>
                    <TableCell>{category.order_in_market}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleEdit(category)}>
                        <Pencil />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(category)}>
                        <Trash />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <CategoryDialog
        item={selectedCategory}
        openDialog={openDialog}
        onCloseDialog={handleSaveCategory}
      />
      <DeleteDialog
        itemName={selectedCategory.name}
        open={openDeleteDialog}
        onDelete={handleDeleteCategory}
      ></DeleteDialog>
    </div>
  );
};

export default Categories;
