import React, { useEffect, useState } from "react";
import type { JSX } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import type { Category } from "src/types/cases";
import { TextField } from "@mui/material";

interface CategoryDialogProps {
  item: Category;
  openDialog: boolean;
  onCloseDialog: (category?: Category) => void;
}

export function CategoryDialog({
  item,
  openDialog,
  onCloseDialog,
}: CategoryDialogProps): JSX.Element {
  const { t } = useTranslation();
  const [category, setCategoryData] = useState<Category>({} as Category);

  useEffect(() => {
    // При каждом открытии диалога обновляем данные
    if (openDialog) {
      setCategoryData(item || {}); // Устанавливаем данные, если они есть
    }
  }, [openDialog, item]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    onCloseDialog(category);
  };

  const handleInvalid = (e: any) => {
    e.target.setCustomValidity(t("COMMON.FORM_VALIDATION_MESSAGE"));
  };

  const handleInput = (e: any) => {
    e.target.setCustomValidity("");
  };

  const handleClose = () => {
    // При закрытии сбрасываем состояние формы
    setCategoryData({} as Category);
    onCloseDialog();
  };

  return (
    <Dialog maxWidth="lg" open={openDialog} onClose={handleClose}>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent>
          <Card>
            <CardContent>
              <TextField
                label={t("COMMON.TITLE")}
                value={category?.name || ""}
                onChange={(e) =>
                  setCategoryData({ ...category, name: e.target.value })
                }
                fullWidth
                margin="dense"
                required
                inputProps={{
                  onInvalid: handleInvalid,
                  onInput: handleInput,
                }}
              />
              <TextField
                label={t("COMMON.MARKET_ORDER")}
                type="number"
                value={category?.order_in_market || ""}
                onChange={(e) =>
                  setCategoryData({
                    ...category,
                    order_in_market: parseInt(e.target.value) || 0,
                  })
                }
                fullWidth
                margin="dense"
                required
                inputProps={{
                  onInvalid: handleInvalid,
                  onInput: handleInput,
                  min: 0,
                }}
              />
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose}>
            {t("COMMON.CANCEL")}
          </Button>
          <Button variant="contained" type="submit">
            {t("COMMON.SAVE")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CategoryDialog;
