import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import { Coins, FolderOpen, HandArrowDown, HandCoins, HandDeposit, IconProps, Invoice, MoneyWavy, Parachute, Percent, Scales, TipJar, Users } from '@phosphor-icons/react';
import { HandWithdraw } from '@phosphor-icons/react/dist/ssr';

export interface BudgetItemProps {
  label: string;
  value?: string;
  color: string;
  icon: string;
  showCurrency?: boolean;
}

const iconSize = 2;
const iconSizeStyle = `${iconSize}rem`;
const backgroundSizeStyle = `${iconSize * 1.5}rem`;

const iconMap: { [key: string]: React.ComponentType<IconProps> } = {
  revenueGross: HandDeposit,
  revenueNet: TipJar,
  withdraws: HandWithdraw,
  profit: MoneyWavy,
  'hand-arrow-down': HandArrowDown,
  'folder-open': FolderOpen,
  'parachute': Parachute,
  'percent': Percent,
  'hand-coins': HandCoins,
  'coins': Coins,
  'payers': Users,
  'invoice': Invoice,
  'scales': Scales
};

export function StatsItem({ label, value, color, icon, showCurrency }: BudgetItemProps): React.JSX.Element {
  const IconComponent = iconMap[icon];
  return (
    <Card sx={{ backgroundColor: `${color}.light` }}>
      <CardContent sx={{ padding: '8px 16px', ":last-child": { paddingBottom: '8px' } }}>
        <Stack direction={'row'} alignItems={'center'}>
          <Stack width={'100%'}>
            <Typography color="text.primary" fontWeight={'bold'} fontSize={8 * iconSize}>
              {label}:
            </Typography>
            <Box display={'flex'}>
              <Typography fontWeight={'bold'} fontSize={10 * iconSize}>{value}</Typography>
              {showCurrency && <Typography padding={'5px'} fontWeight={'bold'} fontSize={6 * iconSize}>RUB</Typography>}
            </Box>
          </Stack>
          <Avatar sx={{ backgroundColor: `${color}.dark`, height: backgroundSizeStyle, width: backgroundSizeStyle }}>
            {IconComponent && <IconComponent fontSize={iconSizeStyle} color={"text.primary"} />}
          </Avatar>
        </Stack>
      </CardContent>
    </Card >
  );
}
